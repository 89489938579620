import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { queryStringToObj , formatPhoneNumber } from 'core/api/utils';

import {
  ERROR_MESSAGES,
  PHONE_REGEXP,
  US_STATES,
} from 'core/api/constants';
import { SET_DELIVERY_INFO_AND_SMS_AND_ACCOUNT_TYPE_PREFERENCES } from 'core/api/apollo/mutations';
import { userHasCourierAddress } from 'core/api/Account';
import { scrollTop } from 'core/api/actions/scrolling';
import { onrampNext } from 'onramp/api/navigation';

import handlesErrors from 'core/ui/components/HandlesErrors';
import withForm from 'core/ui/components/withForm';

import {
  Checkbox,
  ControlsGrid,
  FormGroup,
  Label,
  Select,
  Text,
  ValidatedInput,
  ValidatedTextarea,
} from 'core/ui/atoms';

import {
  ContentButton,
  ContentForm,
  InfoDescription,
  InfoHeader,
  InfoImage,
  Page,
  PageInfo,
  PageContent,
} from 'onramp/ui/components';

import PageImage from 'onramp/assets/images/ShippingAddress/main.png';

const states = US_STATES.map((s) => ({ title: s.name, value: s.abbreviation }));

const getUserTypeFromCurrentUser = (user) => {
  return user.userAccountType || (userHasCourierAddress(user) ? 'courier' : 'shipping');
};

@handlesErrors
@graphql(SET_DELIVERY_INFO_AND_SMS_AND_ACCOUNT_TYPE_PREFERENCES)
class ShippingAddressPage extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    cache: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    handleGenericApolloError: PropTypes.func.isRequired,
    location: PropTypes.object,
  };

  userType = queryStringToObj(this.props.location.search).userAccountType || getUserTypeFromCurrentUser(this.props.currentUser);

  onSubmit = ({ variables: { input } }) => {
    const { mutate } = this.props;
    const userType = this.userType;

    return mutate({
      variables: {
        setDeliveryInfoInput: {
          street1: input.street1,
          street2: input.street2,
          city: input.city,
          instructions: input.instructions,
          phoneNumber: input.phoneNumber,
        },
        setSmsPreferencesInput: {
          receiveReminders: input.receiveReminders,
        },
        setUserAccountInput: {
          accountType: userType,
        },
      },
      onError: this.props.handleGenericApolloError,
    });
  };

  onSuccess = () => {
    onrampNext(this.props.currentUser, this.props.cache);
  };

  render() {
    const {
      currentUser: {
        phoneNumber,
        primaryAddress,
        notificationSettings: { receiveMenuReminderSms: receiveReminders },
      },
    } = this.props;

    const initialData = { ...primaryAddress, phoneNumber, receiveReminders };
    const userType = this.userType;


    return (
      <Page backgroundColor="#EAE2D8">
        <PageInfo>
          <InfoHeader>Where should we send your box?</InfoHeader>
          <InfoDescription>
            Food is perishable. Refrigerate immediately.
          </InfoDescription>
          <InfoImage
            src={PageImage}
            title="Moroccan Tempeh and Lentil Stew + Turmeric Cauliflower"
            subtitle="Cilantro Cauliflower Rice, Baby Spinach, Crunchy Chickpeas, Hemp Seeds, Mint, Parsley, Cilantro, Oregano"
          />
        </PageInfo>
        <PageContent>
          <StepForm
            currentUser={this.props.currentUser}
            initialData={initialData}
            onSubmit={this.onSubmit}
            onSuccess={this.onSuccess}
            userType={userType}
          />
        </PageContent>
      </Page>
    );
  }
}

@withForm('setDeliveryInfo')
class StepForm extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    userType: PropTypes.string,
  };

  userType = this.props.userType;

  isValid = () => {
    const { currentUser, form: { formData: { street1, city, instructions, phoneNumber } } } = this.props;
    const instructionsInvalid = userHasCourierAddress(currentUser) && this.userType === 'courier' && !instructions;
    if (!street1 || !city || instructionsInvalid || !phoneNumber) { return false; }
    const formatted = formatPhoneNumber(phoneNumber) || phoneNumber;
    return formatted.match(new RegExp(PHONE_REGEXP));
  }

  toggleCheckbox = (e) => {
    const { form: { formData, handleChange } } = this.props;
    handleChange({ [e.target.name]: !formData[e.target.name] });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { form: { handleSubmit } } = this.props;
    handleSubmit(this.isValid);
  };

  render() {
    const {
      currentUser: { primaryAddress: { deliveryOption } },
      form: { formData, onChange, isProcessing },
    } = this.props;

    const isReorder = this.props.currentUser.reorder;

    const displayInstructionsInput = userHasCourierAddress(this.props.currentUser) && this.userType === 'courier';

    return (
      <ContentForm
        onError={scrollTop}
        onSubmit={this.onSubmit}
        noValidate
        instantValidate={false}
      >
        <FormGroup>
          <Label required htmlFor="street1">Address</Label>
          <ValidatedInput
            type="text"
            name="street1"
            id="street1"
            placeholder="ex. 123 W. Main St"
            value={formData.street1 || ''}
            onChange={onChange}
            validators={['required']}
            errorMessages={[ERROR_MESSAGES.required]}
            readOnly={isReorder && formData.street1}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="street2">Apartment or Suite</Label>
          <ValidatedInput
            type="text"
            name="street2"
            id="street2"
            placeholder="ex. Apt 007"
            value={formData.street2 || ''}
            onChange={onChange}
            readOnly={isReorder && formData.street2}
          />
        </FormGroup>
        <FormGroup>
          <Label required htmlFor="city">City</Label>
          <ValidatedInput
            type="text"
            name="city"
            id="city"
            placeholder="ex. San Francisco"
            value={formData.city || ''}
            onChange={onChange}
            validators={['required']}
            errorMessages={[ERROR_MESSAGES.required]}
            readOnly={isReorder && formData.city}
          />
        </FormGroup>
        <CustomControlsGrid>
          <FormGroup>
            <Label required htmlFor="states">State</Label>
            <CustomSelect
              id="states"
              options={states}
              selected={formData.state}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label required htmlFor="zipCode">Zip</Label>
            <ValidatedInput
              type="text"
              name="zipCode"
              id="zipCode"
              placeholder="ex. 12345"
              maxLength={5}
              value={formData.zipCode || ''}
              readOnly
            />
          </FormGroup>
        </CustomControlsGrid>
        {displayInstructionsInput &&
          <FormGroup>
            <Label required>{deliveryOption.isOvernight ? 'Overnight ' : ''}Courier Delivery Instructions</Label>
            <InstructionsHintText text-size="small">
              Help couriers find your home in the dark and place your box in the right place. If possible, include a backup option (eg, if the lobby is locked or the gate code doesn’t work).
            </InstructionsHintText>
            <InstructionsTextarea
              name="instructions"
              value={formData.instructions || ''}
              onChange={onChange}
              rows={5}
              validators={['required']}
              placeholder="ex. The gate code is: 123#. You’re at the right house if you see lawn gnomes. Leave box on porch behind potted plant by front door. If gate code doesn’t work, call neighborhood office at 415-245-2231" />
          </FormGroup>
        }
        <FormGroup>
          <Label required>Delivery Phone</Label>
          <ValidatedInput
            name="phoneNumber"
            onChange={onChange}
            value={formatPhoneNumber(formData.phoneNumber) || formData.phoneNumber || ''}
            type="tel"
            placeholder="ex. (123) 456-7890"
            validators={['required', `matchRegexp:${PHONE_REGEXP}`]}
            errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.phone]}
            readOnly={isReorder && formData.phoneNumber}
          />
        </FormGroup>
        <FormGroup>
          <CustomCheckbox
            label="Please send me SMS messages to notify me about order deadlines and delivery."
            name="receiveReminders"
            value="receiveReminders"
            onChange={this.toggleCheckbox}
            checked={formData.receiveReminders}
          />
        </FormGroup>
        <ContentButton type="submit" disabled={!this.isValid() || isProcessing}>
          Continue
        </ContentButton>
      </ContentForm>
    );
  }
}

const CustomControlsGrid = styled(ControlsGrid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 28px;

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
    grid-gap: 0px;
  `};
`;

const CustomSelect = styled(Select)`
  padding: 0;
  .select-selected {
    margin: 0;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  font-size: ${(p) => p.theme.typography.sizes.regular};
  margin-bottom: 15px;
  .checkbox {
    margin-right: 1.5em;
    font-size: ${(p) => p.theme.typography.sizes.big};
  }
`;

const InstructionsHintText = styled(Text)`
  margin-top: -0.1em;
  padding: 0 0 0.5em 1em;
`;

const InstructionsTextarea = styled(ValidatedTextarea)`
  margin: 0;
  width: 100%;
  background-color: transparent;
`;

export default ShippingAddressPage;

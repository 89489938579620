import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import toInteger from 'lodash/parseInt';

import { displaySizeLabel, displayScheduleLabel } from '../../helpers';
import { userHasCourierAddress } from 'core/api/Account';
import Money from 'core/api/Money';
import { programBundleCartVar } from 'core/api/apollo/cache';
import { session } from 'core/api/storage';
import { GET_PROGRAM_BUNDLE, LIST_PROGRAM_OPTIONS } from 'core/api/apollo/queries';
import { CREATE_ONE_TIME_PROGRAM } from 'core/api/apollo/mutations';
import { updateUserQuery } from 'core/api/cache/updaters';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

import {
  Form,
  FormGroup,
  InputGroupLabel,
  Label,
  Radio,
  Select,
  Tooltip,
  Checkbox,
  Hint,
} from 'core/ui/atoms';
import { Loading } from 'core/ui/components';
import { displayDate } from 'core/ui/dateHelpers';

import { onrampNext } from 'onramp/api/navigation';
import PageImage from 'onramp/assets/images/CustomizePlan/main.png';
import { PROGRAM_FOOD_OPTIONS, PROGRAM_LENGTHS } from 'onramp/constants';

import {
  ContentButton,
  ContentForm,
  InfoDescription,
  InfoHeader,
  InfoImage,
  Page,
  PageInfo,
  PageContent,
} from 'onramp/ui/components';

const SESSION_PROGRAM_BUNDLE_CART_KEY = 'PrepaidBundlePage/Cart';
function BundleForm(props) {
  const { programId, scheduleId, sizeId, foodId } = props;
  const { displayApolloResponseErrors } = useErrorHandlers();
  const bundle = programBundleCartVar();

  const defaultNumWeeks = (PROGRAM_LENGTHS.find((l) => l.default) || PROGRAM_LENGTHS[0]).value;

  const [numWeeks, setNumWeeks] = useState(bundle.numWeeks || defaultNumWeeks);
  const [bundleId, setBundleId] = useState(null);
  const [startDayId, setStartDayId] = useState('');
  const [dietryRestriction, setDietryRestriction] = useState(false);
  const [timingRestriction, setTimingRestriction] = useState(false);

  const { loading, refetch: refetchProgramBundle, data } = useQuery(GET_PROGRAM_BUNDLE, {
    variables: { programId, scheduleId, sizeId, numWeeks: parseInt(numWeeks) },
    onCompleted: (response) => {
      const bundleId = response && response.getProgramBundle ? response.getProgramBundle.id : null;
      // eslint-disable-next-line no-unused-vars
      setBundleId((_) => bundleId);
    },
    notifyOnNetworkStatusChange: true,
  });

  const price = data && data.getProgramBundle && data.getProgramBundle.price !== undefined ? data.getProgramBundle.price : 0;
  const availableDeliveryDays = data && data.getProgramBundle && data.getProgramBundle.availableDeliveryDays !== undefined ? data.getProgramBundle.availableDeliveryDays : [];

  const resetState = () => {
    // eslint-disable-next-line no-unused-vars
    setBundleId((_) => null);

    // const event_date = availableDeliveryDays &&  availableDeliveryDays.find((d) => d.dateWithoutOffset === '2024-05-06');
    const default_date = '';

    setStartDayId(default_date);

    refetchProgramBundle();
  };
  useEffect(resetState, [props, refetchProgramBundle, numWeeks]);

  const [createOneTimeProgram, { loading: isSaving }] = useMutation(CREATE_ONE_TIME_PROGRAM, {
    update: (cache, { data: { createOneTimeProgram: { oneTimeProgram, errors } } }) => {
      updateUserQuery(cache, { user: { nextOneTimeProgram: { bundleReservations: null } } }, errors); // clear the reservations array
      updateUserQuery(cache, { user: { nextOneTimeProgram: { ...oneTimeProgram } } }, errors);
    },
  });
  const disabled = [bundleId, numWeeks, startDayId].some(isEmpty) || isSaving || !dietryRestriction || !timingRestriction || !price;

  const onServerError = (error) => {
    const errors = [error].flatten();
    displayApolloResponseErrors(errors);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = { programId, sizeId, foodId, scheduleId, numWeeks, bundleId, startDayId };
    session.setItem(SESSION_PROGRAM_BUNDLE_CART_KEY, JSON.stringify(data));
    programBundleCartVar(data);

    const input = { programBundleId: bundleId, numWeeks: toInteger(numWeeks), startDayId: startDayId };
    createOneTimeProgram({ variables: { input } })
    .then(({ data: { createOneTimeProgram: { errors = [] } } }) => {
      errors.length ? onServerError(errors) : props.onSuccess();
    })
    .catch(({ errors = [] }) => {
      if (errors.length) { onServerError(errors); }
    });
  };

  const formatDateOption = useCallback(
    (availableDeliveryDays) =>
      availableDeliveryDays.map((d) => {
        const title = `${displayDate(d.dateWithoutOffset, 'deliveryDate')}${
          d.locked || d.decOff ? ' (SOLD OUT)' : ''
        }`;

        return {
          title,
          value: d.id,
          disabled: d.locked || d.decOff,
        };
      }),
    []);

  const priceLabel = loading ? '...' : `${Money(price).toFormat()} per week`;

  //TODO: Move logic to Account and fix routing
  // const isPresubOrCouriorRegion = props.currentUser.primaryAddress && props.currentUser.primaryAddress.deliveryOption && props.currentUser.primaryAddress.deliveryOption.deliveryMethod === 'COURIER';

  return (
    <ContentForm onSubmit={onSubmit} noValidate instantValidate={false}>
      <FormGroup role="radiogroup" aria-labelledby="program_length">
        <InputGroupLabel id="program_length">Program Length</InputGroupLabel>
        <InputGroupHint  success={true} key={'program_length-subheading'} >Save 10% if you buy for 3 weeks or longer </InputGroupHint>
        {PROGRAM_LENGTHS.map((option) => (
          <CustomRadio
            key={`numWeeks-${option.value}`}
            name="numWeeks"
            label={option.name}
            value={option.value}
            checked={!!numWeeks && numWeeks === option.value}
            onChange={(e) => setNumWeeks(e.target.value)}
          />
        ))}
      </FormGroup>

      <FormGroup>
        <InputGroupLabel>Order and Dietary Restrictions</InputGroupLabel>
        <CustomCheckbox
          label="I understand that you can't accommodate any food allergies or dietary restrictions, with the exception of allowing up to two meal swaps per reset. I also understand that your menu is free of gluten and dairy."
          name="dietryRestriction"
          value="dietryRestriction"
          onChange={() => setDietryRestriction((value) => !value)}
          checked={dietryRestriction}
        />
      </FormGroup>

      <FormGroup>
      <CustomCheckbox
          label={<span>I understand that if I need to modify or postpone my order, I must email<InnerLink href="mailto:help@gomethodology.com"> help@gomethodology.com</InnerLink> by 9 PM PT on the Sunday one week before my scheduled delivery, and any requests after that cannot be accommodated.</span>}
          name="timingRestriction"
          value="timingRestriction"
          onChange={() => setTimingRestriction((value) => !value)}
          checked={timingRestriction}
        />
      </FormGroup>

      <FormGroup>
        <InputGroupLabel>Start Date</InputGroupLabel>
        {!loading ? (
          <CustomSelect
            name="startDayId"
            options={formatDateOption(availableDeliveryDays)}
            selected={startDayId}
            onChange={(e) => setStartDayId(e.target.value)}
            prompt="Select a start date"
          />
        ) : (
          <CustomSelect
            name="startDayId"
            options={[]}
            selected={''}
            prompt="Loading..."
            readOnly={true}
          />
        )}
      </FormGroup>

      <CustomContentButton type="submit" disabled={loading || disabled}>
        <Price aria-live="polite">{priceLabel}</Price>
        <div>Continue</div>
      </CustomContentButton>
    </ContentForm>
  );
}

BundleForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  sizeId: PropTypes.string.isRequired,
  foodId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  scheduleId: PropTypes.string.isRequired,
};

//--------------

export function StepForm(props) {
  const { onSuccess } = props;
  const bundle = programBundleCartVar();
  const [programId, setProgramId] = useState(bundle.programId);
  const [sizeId, setSizeId] = useState(bundle.sizeId);
  const [foodId, setFoodId] = useState(bundle.foodId);
  const [scheduleId, setScheduleId] = useState(bundle.scheduleId);
  const [schedulesList, setSchedulesList] = useState([]);
  const [filteredSchedulesList, setFilteredSchedulesList] = useState([]);
  const programFoods = PROGRAM_FOOD_OPTIONS;

  useEffect(() => {
    const data = JSON.parse(session.getItem(SESSION_PROGRAM_BUNDLE_CART_KEY) || '{}');
    const { programId, sizeId, foodId, scheduleId, numWeeks, bundleId, startDayId } = data;
    programBundleCartVar({ programId, sizeId, foodId, scheduleId, numWeeks, bundleId, startDayId });
    setProgramId(programId);
    setSizeId(sizeId);
    setFoodId(foodId);
    setScheduleId(scheduleId);
  }, []);

  useEffect(() => {

    if(schedulesList.length && foodId) {
    const selectedFoodPreference = programFoods.find((f)=> f.id === foodId);
    const filteredList = schedulesList.filter((s) => {
      return selectedFoodPreference.tags.includes(s.name);
    });

      setFilteredSchedulesList(sortBy(filteredList, 'name'));
      const defaultSchedule = filteredList.find((s) => s.default) || filteredList[0];

      setScheduleId(defaultSchedule.id);
    }
  }, [foodId, programFoods, schedulesList]);

  // TODO: Implement this as a real attribute on Programs::PrepaidBundle

  const isCourierRegion = userHasCourierAddress(props.currentUser);

  const {
    loading,
    data: {
      listPrepaidPrograms: programs,
      // eslint-disable-next-line no-unused-vars
      listProgramSchedules: programSchedules,
      listProgramSizes: programSizes,
    } = {},
  } = useQuery(LIST_PROGRAM_OPTIONS, {
    onCompleted: ({
      listPrepaidPrograms: programs,
      listProgramSchedules: schedules,
      listProgramSizes: sizes,
    }) => {
      if (!programId && programs) {
        //comment out below code to Disable to AUTOSELECT program.
        const defaultProgram = programs.find((p) => p.default) || programs[0];
        setProgramId(defaultProgram.id);
      }
      if (!sizeId && sizes) {
        const defaultSize = sizes.find((s) => s.default) || sizes[0];
        setSizeId(defaultSize.id);
      }
      if (schedules) {
        setSchedulesList(schedules);
      }
      if (!foodId && programFoods) {
        const defaultFood = programFoods.find((s) => s.default) || schedules[0];
        setFoodId(defaultFood.id);
      }
    },
  });

  const programOptions = sortBy(programs, 'position');
  const sizeOptions = sortBy(programSizes, 'position');

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <Form>
        <FormGroup role="radiogroup" aria-labelledby="foodType">
          <InputGroupLabel id="foodType">Food</InputGroupLabel>
          {programFoods.map((option) => (
            <CustomRadio
              key={`food-${option.id}`}
              name="food"
              label={option.name}
              value={option.id}
              checked={!!foodId && foodId === option.id}
              onChange={(e) => setFoodId(e.target.value)}
            />
          ))}
        </FormGroup>

        <FormGroup role="radiogroup" aria-label="daysPerWeek">
          <CustomInputGroupLabel id="daysPerWeek">Days of Food Per Week</CustomInputGroupLabel>
          <CustomInputGroupSublabel>
            Monday delivery by {isCourierRegion ? '6 AM.' : '5 PM.'}{' '}
          </CustomInputGroupSublabel>
          <CustomSecondInputGroupSublabel>
            Food is perishable. Refrigerate immediately.{' '}
          </CustomSecondInputGroupSublabel>
          {filteredSchedulesList.map((option) => (
            <CustomRadio
              key={`schedule=${option.id}`}
              name="schedule"
              label={displayScheduleLabel(option)}
              value={option.id}
              checked={!!scheduleId && scheduleId === option.id}
              onChange={(e) => setScheduleId(e.target.value)}
            />
          ))}
        </FormGroup>

        <FormGroup role="radiogroup" aria-labelledby="portion_size">
          <InputGroupLabel id="portion_size">Meal Portion Size</InputGroupLabel>
          {sizeOptions.map((option) => (
            <CustomRadio
              key={`size-${option.id}`}
              name="size"
              label={displaySizeLabel(option)}
              value={option.id}
              checked={!!sizeId && sizeId === option.id}
              onChange={(e) => setSizeId(e.target.value)}
            />
          ))}
        </FormGroup>

        <FormGroup role="radiogroup" aria-labelledby="dietType">
          <InputGroupLabel id="dietType">Diet</InputGroupLabel>
          {programOptions.map((option) => (
            <ProgramNameContainer key={`program-${option.id}`}>
              <CustomRadio
                key={`program-input-${option.id}`}
                name="program"
                label={option.name}
                value={option.id}
                checked={!!programId && programId === option.id}
                onChange={(e) => setProgramId(e.target.value)}
              />
              {option.description && (
                <ProgramNameTooltip>
                  <Tooltip key={`program-description-${option.id}`} tipText={option.description} />
                </ProgramNameTooltip>
              )}
            </ProgramNameContainer>
          ))}
        </FormGroup>
      </Form>

      {programId && sizeId && foodId && scheduleId && (
        <BundleForm
          currentUser={props.currentUser}
          onSuccess={onSuccess}
          programId={programId}
          sizeId={sizeId}
          foodId={foodId}
          scheduleId={scheduleId}
        />
      )}
    </Fragment>
  );
}

StepForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

function PrepaidBundlePage(props) {
  const onSuccess = () => onrampNext(props.currentUser, props.cache);

  return (
    <Page backgroundColor="#E7EBE9">
      <PageInfo>
        <InfoHeader>Let’s get to the food!!!</InfoHeader>
        <InfoDescription>Choose your program options.</InfoDescription>
        <InfoImage
          src={PageImage}
          title="Wild Rice Quinoa Harvest Bowl + Sweet Potato Cakes"
          subtitle="Turmeric Tahini, Balsamic Roasted Brussels Sprouts & Apples, Pomegranate Hazelnut Delicata Squash, Micro Mint"
        />
      </PageInfo>

      <PageContent>
        <StepForm onSuccess={onSuccess} currentUser={props.currentUser} cache={props.cache} />
      </PageContent>
    </Page>
  );
}

PrepaidBundlePage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
};

const CustomContentButton = styled(ContentButton)`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
`;

const Price = styled.div`
  flex-grow: 2;
  border-right: 1px solid ${(p) => p.theme.colors.black};
  padding-right: 1em;
  margin-right: 1em;
  small {
    display: block;
  }
`;

const InputGroupSublabel = styled(Label)`
  font-size: 0.8em;
  font-weight: 300;
  font-style: italic;
  padding-bottom: 1em;
  padding-left: 0.5em;
`;

const CustomRadio = styled(Radio)`
  margin-bottom: 1em;
`;

const CustomSelect = styled(Select)`
  margin-bottom: 1em;

  p.select-selected {
    margin: 0.5em 0 2.5em;
  }
`;

const ProgramNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ProgramNameTooltip = styled.div`
  margin-left: 0.5em;
  width: 1.5em;
  border: 1px solid ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.primary};
  border-radius: 1em;
  text-align: center;
  font-size: ${(p) => p.theme.typography.sizes.smaller};
`;

const CustomCheckbox = styled(Checkbox)`
  font-size: ${(p) => p.theme.typography.sizes.small};
  margin-bottom: 15px;
  .checkbox {
    margin-right: 1em;
    font-size: ${(p) => p.theme.typography.sizes.big};
    align-item: top;
  }
`;

const CustomInputGroupLabel = styled(InputGroupLabel)`
  padding-bottom: 0em;
`;

const CustomInputGroupSublabel = styled(InputGroupSublabel)`
  padding-bottom: 0em;
`;

const CustomSecondInputGroupSublabel = styled(InputGroupSublabel)`
  padding-top: 0em;
  padding-left: 0em;
`;

const InputGroupHint = styled(Hint)`
font-size: 0.8em;
padding-left: 0.5em;
margin-left: 0;
font-style: italic;
`;

const InnerLink = styled.a`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default PrepaidBundlePage;

import { goTo } from 'core/api/actions/navigation';
import { getQueryParams, normalizeRoute } from 'core/api/utils';

import { onrampSequenceForUser, ROUTE_MAP } from 'onramp/api/routeSequence';

const findRouteIndex = (routes, pathname = window.location.pathname) => {
  const normalizedPath = normalizeRoute(pathname);
  return routes.findIndex((route) => route.path === normalizedPath);
};

const findRoute = (routes, offset = 0) => routes[findRouteIndex(routes) + offset];

export const nextStep = (user, cache) => {
  const routes = onrampSequenceForUser(user, cache);
  return findRoute(routes, 1);
};

export const previousStep = (user, cache) => {
  const routes = onrampSequenceForUser(user, cache);
  return findRoute(routes, -1);
};

export const onrampNext = (user, cache, qparams = '') => {
  const { next } = getQueryParams();
  if (next) {

    return goTo(ROUTE_MAP[next].path + qparams);
  }

  const route = nextStep(user, cache);
  const path = (route && route.path) || '/';
  return goTo(path + qparams);
};

export const onrampBack = (user, cache) => {
  const route = previousStep(user, cache);
  const path = (route && route.path) || '/';
  return goTo(path);
};
export const prepaidBundlePath = () => ROUTE_MAP.prepaidBundle.path;
export const prepaidReviewPath = () => ROUTE_MAP.prepaidReview.path;
export const prepaidConfirmationPath = () => ROUTE_MAP.prepaidConfirmation.path;

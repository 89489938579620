import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';

import useUser from 'core/hooks/useUser';
import useDeliveryDayStatus from 'dashboard/hooks/useDeliveryDayStatus';

import { Container, Page } from 'core/ui/atoms';
import LoadingScreen from 'core/ui/components/LoadingScreen';
import Cart from 'dashboard/ui/components/CartPage/Cart';
const Welcome = React.lazy(() => import('dashboard/ui/components/CartPage/Welcome'));

function CartPage() {
  const { deliveryDayId } = useParams();
  const status = useDeliveryDayStatus(deliveryDayId);
  const { userCan } = useUser();

  const { search } = useLocation();
  const isWelcoming = ('welcome' in qs.parse(search, { ignoreQueryPrefix: true }));

  return (
    <Page scrollTop>
      <CartContainer>
        {status.loading ?
          <LoadingScreen />
          :
          <Cart status={status} />
        }
      </CartContainer>
      <Suspense fallback={<LoadingScreen />} >
        {isWelcoming && userCan.viewWelcome() && <Welcome open={isWelcoming}/>}
      </Suspense>
    </Page>
  );
}


const CartContainer = styled(Container)`
  ${(p) => p.theme.max('md')`
    padding-right: 0;
    padding-left: 0;
  `}
`;

export default CartPage;

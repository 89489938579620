import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { isMenuOpenVar } from 'core/api/apollo/cache';
import { Box } from 'core/ui/atoms';

class MobileNavClose extends PureComponent {
  static propTypes = {
    opened: PropTypes.bool,
  };

  toggleMenu = (e) => {
    e.preventDefault();
    isMenuOpenVar(!isMenuOpenVar());
  }

  render() {
    const { opened } = this.props;

    return (
      <CloseCont onClick={this.toggleMenu} hiddenMD hiddenLG>
        <Line opened={opened} />
        <Line opened={opened} bottom />
      </CloseCont>
    );
  }
}

const CloseCont = styled(Box)`
  position: relative;
  left: ${(p) => p.theme.layout.mobilePadding};
  width: 25px;
  height: 25px;
  z-index: 10;
  cursor: pointer;
`;

const Line = styled.div`
  position: absolute;
  width: 100%;
  top: 33%;
  height: 2px;
  background: ${(p) => p.theme.colors.black};
  transform-origin: center center;
  transition: .3s ease;
  ${(props) => props.bottom && css`
    transform: translate(0, 400%);
  `}
  ${(props) => props.opened && css`
    transform: translate(0, 100%) rotate(45deg);
  `}
  ${(props) => props.opened && props.bottom && css`
    transform: translate(0, 100%) rotate(-45deg);
  `}
  ${(props) => props.opened && css`
    background: white;
  `}
`;

export default MobileNavClose;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Burst as BurstAtom } from 'onramp/ui/atoms';
import BackButton from 'onramp/ui/components/Page/Info/BackButton';
import PageBox from 'onramp/ui/components/Page/Box';

class PageInfo extends PureComponent {
  static propTypes = {
    hideBackButton: PropTypes.bool,
  };

  render() {
    const { hideBackButton = false, ...rest } = this.props;

    return (
      <PageBox {...rest}>
        {rest.children}
        <Burst className='burst' />
        {!hideBackButton && <CustomBackButton />}
      </PageBox>
    );
  }
}

const Burst = styled(BurstAtom)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 5px;

  transition: transform 0.2s, top 0.2s;

  transform: translate(-50%, -50%);

  ${(p) => p.theme.max('md')`
    top: initial;
    bottom: 0;
    transform: translate(-50%, 0%);
  `}

  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const CustomBackButton = styled(BackButton)`
  position: relative;
  z-index: 10;
  display: inline-block;
  align-self: flex-start;
  margin-top: auto;

  ${(p) => p.theme.max('sm')`
    position: absolute;
    bottom: ${(p) => p.theme.layout.onramp.padding.vert};
  `}
`;

export default PageInfo;

import { css } from 'styled-components';

import Albra from './Albra';
import HelveticaNeue from './HelveticaNeue';
import icomoon from './icomoon';

export default css`
  ${Albra}
  ${HelveticaNeue}
  ${icomoon}
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';
import { useHistory, useLocation } from 'react-router-dom';
import BACKGROUND_IMAGE from 'core/assets/images/bg3.jpg';
import SIGN_UP_IMAGE from 'core/assets/images/bg1.jpg';

import useErrorHandlers from 'core/hooks/useErrorHandlers';
import { userHasShippingAddress } from 'core/api/Account';
import { prepaidConfirmationPath } from 'onramp/api/navigation';

import SplitPageContainer from 'core/ui/components/SplitPageContainer';
import {
  Checkbox,
  Container,
  Flex,
  Box,
  Label,
  FormGroup,
  Button,
  ValidatedInput,
  RouterLink,
  SubHeader,
  Header,
  ArrowIcon,
} from 'core/ui/atoms';
import { SIGN_IN } from 'core/api/apollo/mutations';
import { ERROR_MESSAGES } from 'core/api/constants';
import cacheUpdater, { updateUserQuery } from 'core/api/cache/updaters';
import { isViewportAbove } from 'core/ui/breakpoints';
import { cacheImages } from 'core/api/utils';

function LoginPage() {
  const location = useLocation();
  const history = useHistory();
  const { displayApolloResponseErrors, handleGenericApolloError } = useErrorHandlers();
  const [formData, setFormData] = useState({ email: '', password: '', rememberMe: true });
  const [isProcessing, setIsProcessing] = useState(false);
  const { email, password, rememberMe } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const toggleRememberMe = () => setFormData({ ...formData, rememberMe: !rememberMe });

  const onLogin = ({ signIn: { errors, signedIn, user } }) => {
    const destination = location && location.state && location.state.destination;

    if (signedIn) {
      if (user.reorder && userHasShippingAddress(user)) {
        history.push(prepaidConfirmationPath());
      } else {
        history.push(destination || '/');
      }
    } else if (errors && errors.length) {
      displayApolloResponseErrors(errors);
      setFormData({ ...formData, password: '' });
      setIsProcessing(false);
    }
  };

  const [signIn] = useMutation(SIGN_IN, {
    variables: { input: { email, password, rememberMe } },
    update: cacheUpdater('signIn', updateUserQuery),
    onCompleted: onLogin,
    onError: handleGenericApolloError,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    signIn().catch(() => setIsProcessing(false));
  };

  return (
    <SplitPageContainer noPadding image={BACKGROUND_IMAGE}>
      <Container>
        <Flex>
          <Box md={4} mdOffset={1}>
            <WelcomeHeader data-test-welcome-text>Welcome back!</WelcomeHeader>
            <SubHeader>Log in to your account to view your menu.</SubHeader>
            <Form
              onSubmit={onSubmit}
              noValidate
              instantValidate={false}
            >
              <FormGroup>
                <Label required>Email</Label>
                <ValidatedInput
                  type="email"
                  name="email"
                  onChange={onChange}
                  value={email}
                  validators={['required', 'isEmail']}
                  errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.email]}
                />
              </FormGroup>

              <FormGroup>
                <Label required>Password</Label>
                <PasswordInputWrap>
                  <ValidatedInput
                    type="password"
                    name="password"
                    onChange={onChange}
                    value={password}
                    validators={['required']}
                    errorMessages={[ERROR_MESSAGES.required]}
                  />
                  <ForgotLink to="/forgot-password">Forgot?</ForgotLink>
                </PasswordInputWrap>
              </FormGroup>

              <CheckboxCont>
                <Checkbox
                  checked={rememberMe}
                  onChange={toggleRememberMe}
                  label='Keep me logged in'
                />
              </CheckboxCont>

              <SubmitButtonCont>
                <FormGroup>
                  <SignInButton
                    type="submit"
                    btn-primary
                    btn-lg
                    data-test-sign-in
                    disabled={isProcessing}
                  >
                    Let's grub <ArrowIcon />
                  </SignInButton>
                </FormGroup>

                <RouterLink to="/sign-up" onMouseEnter={cacheSignupImage}>
                  Not a member? Sign up!
                </RouterLink>
              </SubmitButtonCont>
            </Form>
          </Box>
        </Flex>
      </Container>
    </SplitPageContainer>
  );
}

const cacheSignupImage = () => {
  if (isViewportAbove('md')) {
    cacheImages(SIGN_UP_IMAGE.image);
  }
};

const WelcomeHeader = styled(Header)`
  margin-top: 5vh;
`;

const Form = styled(ValidatorForm)`
  display: block;
  margin: 7vh auto 3vh auto;
  ${(p) => p.theme.min('sm')`
    width: 320px;
  `}
  ${(p) => p.theme.max('sm')`
    padding: 0 calc(${(p) => p.theme.layout.mobilePadding} * 2);
  `}
`;

const CheckboxCont = styled.div`
  font-size: ${(p) => p.theme.typography.sizes.base};
  margin-top: 3vh;
  label {
    margin-left: 10px;
    ${(p) => p.theme.max('md')`
      position: relative;
      top: -10px;
      margin-left: 12px;
    `}
  }
`;

const SubmitButtonCont = styled.div`
  text-align: center;
  margin-top: 12vh;
  ${(p) => p.theme.max('md')`
    margin-top: 4vh;
    margin-bottom: ${p.theme.layout.headerHeight};
  `}
`;

const SignInButton = styled(Button)`
  position: relative;
  width: 80%;
  padding-left: 0;
  padding-right: 0;
  ${(p) => p.theme.max('md')`
    width: 100%;
  `}
`;

const PasswordInputWrap = styled.div`
  position: relative;
`;

const ForgotLink = styled(RouterLink)`
  position: absolute;
  font-size: 12px;
  right: 0;
  top: 12px;
`;

export default LoginPage;

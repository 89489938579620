import React from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import Header from 'core/ui/components/Header/Header';
import { EXTERNAL_LINKS } from 'core/api/constants';
import { menuPath } from 'core/api/actions/navigation';
import { NavItem, MobileNavItem } from 'core/ui/atoms';

function VisitorHeader() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isShipping = searchParams.get('delivery');
  const zipcode = searchParams.get('zipcode');
  const queryParams = isShipping? { delivery: isShipping, zipcode  }: {};

  const leftNavItems = () => {
    return [
      <NavItem href={EXTERNAL_LINKS.home} key="nav-home">Home</NavItem>,
      <NavItem to={menuPath(null,queryParams)} key="nav-menu">Sample Menu</NavItem>,
    ];
  };

  const rightNavItems = () => {
    return [
      <NavItem to="/sign-in" activeClassName='hidden' key="nav-sign-in">Log In</NavItem>,
      <SignUpButton to="/sign-up" data-test-sign-up key="nav-sign-up">Sign Up</SignUpButton>,
    ];
  };

  const mobileNavItems = () => {
    return [
      <MobileNavItem href={EXTERNAL_LINKS.home} key="nav-home">Home</MobileNavItem>,
      <MobileNavItem to={menuPath(null,queryParams)} key="nav-menu">Sample Menu</MobileNavItem>,
      <MobileNavItem to="/sign-in" key="nav-sign-in">Log In</MobileNavItem>,
      <MobileNavItem to="/sign-up" key="nav-sign-up">Sign Up</MobileNavItem>,
    ];
  };

  return (
    <Header leftNavItems={leftNavItems} rightNavItems={rightNavItems} mobileNavItems={mobileNavItems} />
  );
}

const SignUpButton = styled(NavItem)`
  color: ${(p) => p.theme.typography.colors.inverse};
  background: ${(p) => p.theme.colors.primary};
  padding: 7px 28px;
  font-size: 13px;
`;

export default VisitorHeader;

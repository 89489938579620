import _kebabCase from 'lodash/kebabCase';
import { userHasCourierAddress, userHasShippingAddress, userHasPreSubscribedAccountType } from 'core/api/Account';

export const ONRAMP_ROUTES_NAMESPACE = 'onramp';

export const onrampPath = (step) => `/${ONRAMP_ROUTES_NAMESPACE}/${_kebabCase(step)}`;

const isPreSubscribedComplete = (user) => {
  //TODO: fix this condition
  if (userHasPreSubscribedAccountType(user) && userHasCourierAddress(user)) {
      return !!(
        user &&
        user.subscription &&
        user.subscription.subscriptionQuantities &&
        user.subscription.subscriptionQuantities.length) ||  !!(
        user &&
        user.nextOneTimeProgram &&
        user.nextOneTimeProgram.programBundle &&
        user.nextOneTimeProgram.programBundle.id);
  }
  return false;
};


export const ROUTES = [
  {
    name: 'signIn', //This is used to sign in users who have not created any orders or passwords.
    isComplete: (user) => !!(user && user.email && user.primaryAddress && user.primaryAddress.zipCode),
  },
  {
    name: 'signUp',
    isComplete: (user) => !!(user && user.email && user.primaryAddress && user.primaryAddress.zipCode),
  },
  {
    name: 'aboutYou',
    isComplete: (user) => !!(user && user.motivation && user.gender),
    enable: (user) => user && !user.reorder,
  },
  {
    name: 'diet',
    isComplete: (user) => !!(user && user.diet),
    enabled: (user) => userHasCourierAddress(user) && (user && !user.reorder),
  },
  {
    name: 'customizePlan',
    isComplete: (user) =>
      !!(
        user &&
        user.subscription &&
        user.subscription.subscriptionQuantities &&
        user.subscription.subscriptionQuantities.length
      ),
    enabled: (user) => userHasCourierAddress(user) && !userHasPreSubscribedAccountType(user),
  },
  {
    name: 'prepaidBundle',
    isComplete: (user) =>
      !!(
        user &&
        user.nextOneTimeProgram &&
        user.nextOneTimeProgram.programBundle &&
        user.nextOneTimeProgram.programBundle.id
      ),
    enabled: (user) => (userHasShippingAddress(user) && !userHasPreSubscribedAccountType(user)) || (user && user.userAccountType === 'shipping')  ,
  },
  {
    name: 'preSubscribedPlan',
    isComplete: (user) => isPreSubscribedComplete(user),
    enabled: (user) => userHasPreSubscribedAccountType(user),
  },
  {
    name: 'shippingAddress',
    isComplete: (user) => !!(user && user.primaryAddress && user.primaryAddress.street1 && user.primaryAddress.city),
  },
  {
    name: 'billingInfo',
    isComplete: (user) => !!(user && user.billingAccount && user.billingAccount.active),
  },
  {
    name: 'review',
    isComplete: (user) => !!(user && user.subscription && user.subscription.activationDate),
    enabled: (user) => userHasCourierAddress(user) && !userHasPreSubscribedAccountType(user),
  },
  {
    name: 'prepaidReview',
    isComplete: (user) => !!(user && user.nextOneTimeProgram && user.nextOneTimeProgram.invoiced),
    enabled: (user) => userHasShippingAddress(user) && !userHasPreSubscribedAccountType(user),
  },
  {
    name: 'prepaidConfirmation',
    enabled: (user) => userHasShippingAddress(user),
  },
].map((route) => ({ path: onrampPath(route.name), ...route }));

const UNSEQUENCED_ROUTES = [
  {
    name: 'outOfDeliveryZone',
  },
].map((route) => ({ path: onrampPath(route.name), ...route }));

export const ROUTE_MAP = [...ROUTES, ...UNSEQUENCED_ROUTES].reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export const onrampStart = () => ROUTES[0].path;

export const onrampSequenceForUser = (user, cache) =>
  ROUTES.filter((route) => route.enabled ? route.enabled(user, cache): true);

// FIXME: this should use onrampSequenceForUser
export const stepNumber = (pathName) => ROUTES.findIndex((r) => r.path === pathName.replace(/\/+$/, '')) + 1;

// FIXME: this should use onrampSequenceForUser
export const stepCount = () => ROUTES.length;

export default ROUTES;

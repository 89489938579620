import React, { Fragment, PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { userHasCourierAddress, userHasShippingAddress } from 'core/api/Account';
import { ROUTE_MAP } from 'onramp/api/routeSequence';
import { GET_BILLING_INFO } from 'core/api/apollo/queries';
import { SET_BILLING_INFO } from 'core/api/apollo/mutations';
import { updateUserQuery } from 'core/api/cache/updaters';
import { ControlsGrid, FormGroup, H4, Label, StripeCardInput, StripeExpiryInput, StripeCVCInput } from 'core/ui/atoms';
import handlesErrors from 'core/ui/components/HandlesErrors';
import { Loading } from 'core/ui/components';
import StripeContext from 'core/ui/components/Stripe/Context';

import { onrampNext } from 'onramp/api/navigation';

import {
  ContentButton,
  ContentForm,
  ContentSection,
  FirstWeekSummary,
  InfoDescription,
  InfoHeader,
  InfoImage,
  OneTimeProgramSummary,
  PageContent,
  PageInfo,
  Page,
  PrepaidPurchaseSummary,
  SubscriptionSummary,
} from 'onramp/ui/components';

import PageImage from 'onramp/assets/images/BillingInfo/main.png';

class BillingInfoPage extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    cache: PropTypes.object.isRequired,
  };

  onSuccess = () => onrampNext(this.props.currentUser, this.props.cache);

  isShippingFlow = userHasShippingAddress(this.props.currentUser) ||this.props.currentUser.userAccountType === 'shipping';
  render() {
    return (
      <Page backgroundColor="#FFF8F0">
        <PageInfo>
          <InfoHeader>Checkout</InfoHeader>
          {userHasCourierAddress(this.props.currentUser) &&
            <InfoDescription>You'll view and edit your order for your first week after checkout.</InfoDescription>
          }
          <InfoImage
            src={PageImage}
            title="Chioggia Beet Poke Bowl"
            subtitle="Nori Furikake Brown Rice, Edamame, Wakame Seaweed Salad, Yuzu Pickles, Sesame Seeds, Ponzu Sauce"
          />
        </PageInfo>
        <PageContent>
          {this.isShippingFlow ?
            <Fragment>
              <ContentSection spaced highlight>
                <OneTimeProgramSummary returnTo={ROUTE_MAP.billingInfo.name} />
              </ContentSection>

              <ContentSection spaced highlight>
                <PrepaidPurchaseSummary enablePromoCodeForm={true} />
              </ContentSection>
            </Fragment>
            :
            <Fragment>
              <ContentSection spaced highlight>
                <SubscriptionSummary returnTo={ROUTE_MAP.billingInfo.name} />
              </ContentSection>

              <ContentSection spaced highlight>
                <FirstWeekSummary enablePromoCodeForm={true} />
              </ContentSection>
            </Fragment>
          }

          <StripeContext>
            <StepForm onSuccess={this.onSuccess} inShippingFlow={this.isShippingFlow} />
          </StripeContext>
        </PageContent>
      </Page>
    );
  }
}

@handlesErrors
@graphql(GET_BILLING_INFO)
@graphql(SET_BILLING_INFO, {
  options: {
    update: (cache, { data: { setBillingInfo: { billingAccount, errors } } }) => {
      updateUserQuery(cache, { user: { billingAccount } }, errors);
    },
  },
})
class StepForm extends PureComponent {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    stripe: PropTypes.object,
    elements: PropTypes.object,
    data: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    displayApolloResponseErrors: PropTypes.func.isRequired,
    getCardElement: PropTypes.func,
    inShippingFlow: PropTypes.bool.isRequired,
  };

  state = {
    ready: false,
    isSubmitted: false,
    isProcessing: false,
  };

  setIsProcessing = () => this.setState({ isProcessing: true });
  unsetIsProcessing = () => this.setState({ isProcessing: false });
  setIsSubmitted = () => this.setState({ isSubmitted: true });
  unsetIsSubmitted = () => this.setState({ isSubmitted: false });

  // the form isn't ready until at least one of the stripe input fields is ready.
  // if the form is submitted before this, the page will throw an error.
  isReady = () => this.state.ready;

  setReady = () => this.setState({ ready: true });

  saveBillingInfo = (token) => {
    const { mutate, onSuccess } = this.props;

    mutate({ variables: { input: { stripeToken: token.id } } })
      .then(({ data: { setBillingInfo: { errors } } }) => {
        errors.length ? this.onServerError(errors) : onSuccess();
      })
      .catch(({ error }) => {
        this.onServerError(error);
      });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { stripe, elements, getCardElement } = this.props;

    this.setIsSubmitted();
    this.setIsProcessing();

    if (!stripe || !elements) {
      this.onError();
      return;
    }

    stripe.createToken(getCardElement()).then(({ error, token }) => {
      error ? this.onServerError(error) : this.saveBillingInfo(token);
    });
  };

  onError = () => {
    this.unsetIsProcessing();
  };

  onServerError = (error) => {
    this.onError();
    const errors = [error].flatten();
    this.props.displayApolloResponseErrors(errors);
  };

  render() {
    const { inShippingFlow, data: { loading } } = this.props;
    const { isSubmitted } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <ContentForm onSubmit={this.onSubmit} noValidate instantValidate={false}>
        <ContentSection spaced highlight>
          <H4 text-center>Billing Info</H4>
          <FormGroup>
            <Label required>Card Number</Label>
            <StripeCardInput
              placeholder="ex. 4111 1111 1111 1111"
              onReady={this.setReady}
              showInvalidState={isSubmitted}
            />
          </FormGroup>
          <CustomControlsGrid>
            <FormGroup>
              <Label required>Exp date</Label>
              <StripeExpiryInput
                placeholder="ex. 11/23"
                showInvalidState={isSubmitted}
              />
            </FormGroup>
            <FormGroup>
              <Label required>CVC</Label>
              <StripeCVCInput
                placeholder="ex. 123"
                showInvalidState={isSubmitted}
              />
            </FormGroup>
          </CustomControlsGrid>
        </ContentSection>
        <ContentButton type="submit" disabled={!this.isReady() || this.state.isProcessing}>
          Review {inShippingFlow ? 'purchase' : 'subscription'}
        </ContentButton>
      </ContentForm>
    );
  }
}

const CustomControlsGrid = styled(ControlsGrid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 28px;

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
    grid-gap: 0px;
  `};
`;

export default BillingInfoPage;

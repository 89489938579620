import _get from 'lodash/get';

import RouteAccessCheck, {
  canOrder,
  supportedAddress,
  courierAddress,
  shippingAddress,
  userAuthenticated,
  userUnauthenticated,
  unsupportedAddress,
  preSubscribedAccountType,
  shippingAccountType,
  emptyAccountType,
} from 'core/api/routeAccessCheck';
import Tracker from 'core/api/tracking/tracker';

import { onrampSequenceForUser, ROUTE_MAP as SEQUENCE_MAP } from 'onramp/api/routeSequence';

import OnrampLayout from 'onramp/ui/layouts/OnrampLayout';
import SignInExistingUserPage from 'onramp/ui/pages/SignInExistingUserPage';
import SignUpPage from 'onramp/ui/pages/SignUpPage';
import AboutYouPage from 'onramp/ui/pages/AboutYouPage';
import DietPage from 'onramp/ui/pages/DietPage';
import CustomizePlanPage from 'onramp/ui/pages/CustomizePlanPage';
import PrepaidBundlePage from 'onramp/ui/pages/PrepaidBundlePage';
import ShippingAddressPage from 'onramp/ui/pages/ShippingAddressPage';
import BillingInfoPage from 'onramp/ui/pages/BillingInfoPage';
import ReviewPage from 'onramp/ui/pages/ReviewPage';
import PrepaidReviewPage from 'onramp/ui/pages/PrepaidReviewPage';
import PrepaidConfirmationPage from 'onramp/ui/pages/PrepaidConfirmationPage';
import OutOfZonePage from 'onramp/ui/pages/OutOfZonePage';
import PreSubscribedPlanPage from 'onramp/ui/pages/PreSubscribedPlanPage';

export const canNotOrder = new RouteAccessCheck({
  check: (user, cache) => !canOrder.check(user, cache),
  pathname: '/',
  name: 'canNotOrder',
});

// NOTE: this check fails only if the user has an invoiced program that is also unlocked
const noUnlockedOneTimePrograms = new RouteAccessCheck({
  check: (user) => {
    const unlocked = _get(user, 'nextOneTimeProgram.unlocked', false);
    const invoiced = _get(user, 'nextOneTimeProgram.invoiced', false);
    return (!invoiced || !unlocked || user.reorder);
  },
  pathname: '/onramp/prepaid-confirmation',
  name: 'noUnlockedOneTimePrograms',
});

const isShippingAddressAndRedirectionCheck =
new RouteAccessCheck({
  check: (user) => {
    if (!shippingAddress.check(user)) return true;
    if (user.reorder) return false; //Account type N/A
    return true;
  },
  pathname: '/onramp/prepaid-bundle',
  name: 'isShippingAddressAndRedirectionCheck',
});

const isCourierAddressAndRedirectionPrepaidCheck =
new RouteAccessCheck({
  check: (user) => {
    if (!courierAddress.check(user)) return true;
    if (shippingAccountType.check(user) && user.reorder) return false;
    return true;
  },
  pathname: '/onramp/prepaid-bundle',
  name: 'isCourierAddressAndRedirectionPrepaidCheck',
});

const isCourierAddressAndRedirectionPreSubscribedCheck =
new RouteAccessCheck({
  check: (user) => {
    if (!courierAddress.check(user)) return true;
    if (preSubscribedAccountType.check(user) && user.reorder) return false;
    return true;
  },
  pathname: '/onramp/pre-subscribed-plan',
  name: 'isCourierAddressAndRedirectionPreSubscribedCheck',
});

const isNotEmptyAccountType =
new RouteAccessCheck({
  check:(user)=> !emptyAccountType.check(user),
  pathname: '/onramp/about-you',
  name: 'isNotEmptyAccountType',
});

const onrampUserUnauthenticated = new RouteAccessCheck({
  check: userUnauthenticated.check,
  pathname: SEQUENCE_MAP.aboutYou.path,
  name: 'onrampUserUnauthenticated',
});

const onrampSupportedAddress = new RouteAccessCheck({
  check: (user, cache) => {
    if (_get(user, 'primaryAddress.deliveryOption.adminOnly')) {
      return false;
    }

    return supportedAddress.check(user, cache);
  },
  pathname: SEQUENCE_MAP.outOfDeliveryZone.path,
  name: 'onrampSupportedAddress',
});

const onrampCourierAddress = new RouteAccessCheck({
  check: courierAddress.check,
  pathname: courierAddress.pathname,
  name: 'onrampCourierAddress',
});

const onrampShippingAddressOrShippingAccountType = new RouteAccessCheck({
  check:(user) => shippingAddress.check(user) || shippingAccountType.check(user),
  pathname: shippingAddress.pathname,
  name: 'onrampShippingAddressOrShippingAccountType',
});
const onrampPreSubscribedAccountType = new RouteAccessCheck({
  check: preSubscribedAccountType.check,
  pathname: preSubscribedAccountType.pathname,
  name: 'preSubscribedAccountType',
});

const onrampUnsupportedAddress = new RouteAccessCheck({
  check: (user, cache) => !onrampSupportedAddress.check(user, cache),
  pathname: unsupportedAddress.pathname,
  name: 'onrampUnsupportedAddress',
});

// FIXME: the redirect pathname should be the first authenticated onramp route (not hardcoded to /onramp/about-you)
// Onramp route exists for user
export const onrampAccessCheck = (route) => (new RouteAccessCheck({
  check: (user, cache) => {
    const sequence = onrampSequenceForUser(user, cache);
    const currentIndex = sequence.findIndex((step) => step.name === route.name);
    /* eslint-disable-next-line no-console */
    console.log(sequence, currentIndex);

    if (currentIndex < 0) {
      return false; // route is not valid for the user
    }

    const prevSteps = sequence.slice(0, currentIndex);

    return prevSteps.every((step) => step.isComplete(user, cache));
  },
  pathname: SEQUENCE_MAP.aboutYou.path,
  name: 'onrampAccessCheck',
}));

export const ROUTES = [
  {
    ...SEQUENCE_MAP.signIn, //This is used to sign in users who have not created any orders or passwords.
    component: SignInExistingUserPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
      inverse: true,
      disableProgress: true,
    },
    accessChecks: [
      onrampUserUnauthenticated,
    ],
  },
  {
    ...SEQUENCE_MAP.signUp,
    component: SignUpPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
      inverse: true,
      disableProgress: true,
    },
    accessChecks: [
      onrampUserUnauthenticated,
    ],
  },
  {
    ...SEQUENCE_MAP.aboutYou,
    component: AboutYouPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      isShippingAddressAndRedirectionCheck,
      isCourierAddressAndRedirectionPrepaidCheck,
      isCourierAddressAndRedirectionPreSubscribedCheck,
      onrampSupportedAddress,
      onrampAccessCheck(SEQUENCE_MAP.aboutYou),
    ],
  },
  {
    ...SEQUENCE_MAP.diet,
    component: DietPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampCourierAddress,
      isShippingAddressAndRedirectionCheck,
      isCourierAddressAndRedirectionPrepaidCheck,
      isCourierAddressAndRedirectionPreSubscribedCheck,
      onrampAccessCheck(SEQUENCE_MAP.diet),
    ],
  },
  {
    ...SEQUENCE_MAP.preSubscribedPlan,
    component: PreSubscribedPlanPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampCourierAddress,
      onrampPreSubscribedAccountType,
      onrampAccessCheck(SEQUENCE_MAP.preSubscribedPlan),
    ],
  },
  {
    ...SEQUENCE_MAP.prepaidBundle,
    component: PrepaidBundlePage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampShippingAddressOrShippingAccountType,
      onrampAccessCheck(SEQUENCE_MAP.prepaidBundle),
    ],
  },
  {
    ...SEQUENCE_MAP.customizePlan,
    component: CustomizePlanPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampCourierAddress,
      onrampAccessCheck(SEQUENCE_MAP.customizePlan),
    ],
  },
  {
    ...SEQUENCE_MAP.shippingAddress,
    component: ShippingAddressPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    onLoad: () => Tracker.trackCheckoutProgress({ step: 1 }),
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampAccessCheck(SEQUENCE_MAP.shippingAddress),
    ],
  },
  {
    ...SEQUENCE_MAP.billingInfo,
    component: BillingInfoPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    onLoad: () => Tracker.trackCheckoutProgress({ step: 2 }),
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampAccessCheck(SEQUENCE_MAP.billingInfo),
    ],
  },
  {
    ...SEQUENCE_MAP.review,
    component: ReviewPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    onLoad: () => Tracker.trackCheckoutProgress({ step: 3 }),
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      isNotEmptyAccountType,
      onrampAccessCheck(SEQUENCE_MAP.review),
    ],
  },
  {
    ...SEQUENCE_MAP.prepaidReview,
    component: PrepaidReviewPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    onLoad: () => Tracker.trackCheckoutProgress({ step: 3 }),
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      noUnlockedOneTimePrograms,
      onrampSupportedAddress,
      onrampAccessCheck(SEQUENCE_MAP.prepaidReview),
    ],
  },
  {
    ...SEQUENCE_MAP.prepaidConfirmation,
    component: PrepaidConfirmationPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      userAuthenticated,
    ],
  },
  {
    ...SEQUENCE_MAP.outOfDeliveryZone,
    component: OutOfZonePage,
    layout: OnrampLayout,
    layoutProps: {
      disableProgress: true,
    },
    accessChecks: [
      userAuthenticated,
      onrampUnsupportedAddress,
    ],
  },
];

const ROUTE_MAP = ROUTES.reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export default ROUTE_MAP;

import React from 'react';
import PropTypes from 'prop-types';

import { onrampNext } from 'onramp/api/navigation';
import OrderCreator from 'onramp/ui/components/CustomizePlanPage/OrderCreator';
import { InfoDescription, InfoHeader, InfoImage, Page, PageInfo, PageContent } from 'onramp/ui/components';

import PageImage from 'onramp/assets/images/CustomizePlan/main.png';

export function StepForm(props) {

  return (
      <OrderCreator
        currentUser={props.currentUser}
        onSuccess={props.onSuccess}
      />
  );
}

StepForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  frequencyRadioVisible: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
};

function CustomizePlanPage(props) {
  const onSuccess = () => onrampNext(props.currentUser, props.cache);

  return (
    <Page backgroundColor="#E7EBE9">
      <PageInfo>
        <InfoHeader>Let’s get to the food!!!</InfoHeader>
        <InfoDescription>Choose your subscription options.</InfoDescription>
        <InfoImage
          src={PageImage}
          title="Wild Rice Quinoa Harvest Bowl + Sweet Potato Cakes"
          subtitle="Turmeric Tahini, Balsamic Roasted Brussels Sprouts & Apples, Pomegranate Hazelnut Delicata Squash, Micro Mint"
        />
      </PageInfo>
      <PageContent>

      <StepForm
        currentUser={props.currentUser}
        cache={props.cache}
        onSuccess={onSuccess}
      />
    </PageContent>

    </Page>
  );
}

CustomizePlanPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
};

export default CustomizePlanPage;

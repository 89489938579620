import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';

import client from 'core/api/apollo/apolloClient';
import { accessChecksDisabledVar } from 'core/api/apollo/cache';
import Money from 'core/api/Money';
import { GET_SUBSCRIPTION_PURCHASE } from 'core/api/apollo/queries';
import { ACTIVATE_SUBSCRIPTION } from 'core/api/apollo/mutations';
import Tracker from 'core/api/tracking/tracker';
import TikTokTracker from 'core/api/tracking/vendors/Tiktok';
import { handlesErrors } from 'core/ui/components';
import { goToCart } from 'core/api/actions/navigation';

import { ROUTE_MAP } from 'onramp/api/routeSequence';
import {
  ContentSection,
  ContentButton,
  EditReminder,
  FirstWeekSummary,
  InfoDescription,
  InfoHeader,
  InfoImage,
  Page,
  PageContent,
  PageInfo,
  SubscriptionSummary,
} from 'onramp/ui/components';
import AccountCredentials from 'onramp/ui/components/ReviewPage/AccountCredentials';
import ProcessingPurchaseOverlay from 'onramp/ui/components/ReviewPage/ProcessingPurchaseOverlay';

import PageImage from 'onramp/assets/images/Review/main.png';

@graphql(GET_SUBSCRIPTION_PURCHASE, { options: { fetchPolicy: 'network-only' } })
class ReviewPage extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    cache: PropTypes.object.isRequired,
  };

  onSuccess = () => {
    const { data: { getSubscriptionPurchase: purchase = {} } } = this.props;
    const { email, phoneNumber, subscription } = this.props.currentUser;

    const deliveryFee = purchase.deliveryFee.amount;
    const serviceFee = purchase.serviceFee.amount;
    const coupon = purchase.coupon;

    const tiktokParams = {
      content_type: 'product',
      content_id: `subs-${subscription.subsId}`,
      quantity: purchase.orders.length,
      price: ((purchase.subtotal.amount + serviceFee + deliveryFee) / 100),
      value: purchase.total.amount / 100,
      currency: 'USD',
    };

    if (!!coupon) {
      tiktokParams.query = coupon.code;
    }

    Tracker.trackPurchase({
      transactionId: Date.now().toString(),
      purchaseAmount: Money(purchase.total),
      orderInfo: tiktokParams,
    });

    TikTokTracker.events.subscription(email, phoneNumber, tiktokParams);
    // NOTE: force the currentUser to be reloaded
    // NOTE: temporarily disable route access checks so the reset doesn't automatically
    //       redirect to the root path
    accessChecksDisabledVar(true);
    client.resetStore().then(() => {
      goToCart(purchase.startDay.id, { welcome: true });
      accessChecksDisabledVar(false);
    });
  };

  render() {
    const { currentUser } = this.props;

    return (
      <Page backgroundColor="#EFECE9">
        <PageInfo>
          <InfoHeader>Review your subscription</InfoHeader>
          <InfoDescription>Each week we auto-assign a personalized order for you based on your preferences.</InfoDescription>
          <InfoImage
            src={PageImage}
            title='"Chickpea of the Sea" Niçoise Salad'
            subtitle="Picholine & Casteltravano Olives, Chioggia Beet, Haricot Vert, Thyme Roasted Marble Potatoes, Japanese Sweet Potatoes, Lemon Caper Flax Vinaigrette"
          />
        </PageInfo>
        <PageContent>
          <ContentSection spaced highlight>
            <SubscriptionSummary returnTo={ROUTE_MAP.review.name} />
          </ContentSection>

          <ContentSection spaced highlight>
            <EditReminder />
          </ContentSection>

          <ContentSection spaced highlight>
            <FirstWeekSummary />
          </ContentSection>

          <SubscriptionActivator
            currentUser={currentUser}
            onSuccess={this.onSuccess}
          />
        </PageContent>
      </Page>
    );
  }
}

@graphql(ACTIVATE_SUBSCRIPTION)
@handlesErrors
class SubscriptionActivator extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    displayApolloResponseErrors: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
  };

  state = {
    isProcessing: false,
    backgroundJobId: null,
  };

  onError = (errors) => {
    const { displayApolloResponseErrors } = this.props;
    this.setState({ isProcessing: false, backgroundJobId: null });
    displayApolloResponseErrors(errors);
  };

  onAccountCredentialSuccess = () => {
    const { mutate } = this.props;

    this.setState({ isProcessing: true });

    return mutate({ variables: { input: { } } }).then(({ data }) => {
      const { activateSubscription: { backgroundJob } } = data;
      this.setState({ backgroundJobId: backgroundJob.id });
    });
  };

  render() {
    const { currentUser, onSuccess } = this.props;
    const { isProcessing, backgroundJobId } = this.state;

    return (
      <Fragment>
        { !currentUser.reorder ? (<AccountCredentials
        currentUser={currentUser}
        onSuccess={this.onAccountCredentialSuccess}
      /> ) : (
        <ContentButton type="submit" onClick={this.onAccountCredentialSuccess}>
        {'Start Subscription'}
      </ContentButton>
      )}
        <ProcessingPurchaseOverlay
          open={isProcessing || backgroundJobId}
          backgroundJobId={backgroundJobId}
          onSuccess={onSuccess}
          onError={this.onError}
        />
      </Fragment>
    );
  }
}

export default ReviewPage;

import Facebook from './vendors/Facebook';
import GoogleTagManager from './vendors/GoogleTagManager';
import GoogleAdManager from './vendors/GoogleAdManager';
import Nextdoor from './vendors/Nextdoor';
import ShareASale from './vendors/ShareASale';
import Tiktok from './vendors/Tiktok';
import ShopMyShelf from './vendors/ShopMyShelf';
import { reportError } from 'core/api/errors';
import { session } from 'core/api/storage';

import { selectedFoodTypesList } from 'onramp/helpers';

const SUBSCRIPTION_DETAILS_KEY = 'subscriptionDetails';
const vendors = [
  Facebook,
  GoogleTagManager,
  Nextdoor,
  ShareASale,
  GoogleAdManager,
  Tiktok,
  ShopMyShelf,
];

function trackEvent(event, data) {
  vendors.forEach((vendor) => {
    try {
      vendor.events[event](data);
    } catch(e) {
      reportError(e, { vendor: vendor.name });
    }
  });
}

const stashSubscriptionDetails = ({ numDays, productSize: { name: sizeName }, estimatedPrice, ...foodOptions }) => {
  const features = selectedFoodTypesList(foodOptions).join(', ');
  const subscriptionDetails = JSON.stringify({
    name: `${features} - ${numDays} Days`,
    variant: sizeName,
    price: estimatedPrice,
  });
  session.setItem(SUBSCRIPTION_DETAILS_KEY, subscriptionDetails);
  return subscriptionDetails;
};

const fetchSubscriptionDetails = () => JSON.parse(session.getItem(SUBSCRIPTION_DETAILS_KEY));

const Tracker = {
  stashSubscriptionDetails: stashSubscriptionDetails,
  trackPageView: (data) => trackEvent('pageView', data),
  trackLead: (data) => trackEvent('lead', data),
  trackRegistration: (data) => trackEvent('registration', data),
  trackAddToCart: (data) => trackEvent('addToCart', { ...fetchSubscriptionDetails(), ...data }),
  trackApplyPromoCode: (data) => trackEvent('applyPromoCode', data),
  trackCheckoutProgress: (data) => trackEvent('checkoutProgress', { ...fetchSubscriptionDetails(), ...data }),
  trackPurchase: (data) => trackEvent('purchase', { ...fetchSubscriptionDetails(), ...data }),
  trackError: (data) => trackEvent('error', data),
};

export default Tracker;

import { userUnauthenticated } from 'core/api/routeAccessCheck';

import DefaultLayout from 'core/ui/layouts/DefaultLayout';
import ErrorLayout from 'core/ui/layouts/ErrorLayout';

import LoginPage from 'core/ui/pages/LoginPage';
import ForgotPasswordPage from 'core/ui/pages/ForgotPassword';
import ResetPasswordPage from 'core/ui/pages/ResetPasswordPage';
import NotFound from 'core/ui/pages/NotFound';

export const ROUTES = [
  {
    name: 'signIn',
    path: '/sign-in',
    component: LoginPage,
    layout: DefaultLayout,
    accessChecks: [userUnauthenticated],
  },
  {
    name: 'forgotPassword',
    path: '/forgot-password',
    component: ForgotPasswordPage,
    layout: DefaultLayout,
    accessChecks: [userUnauthenticated],
  },
  {
    name: 'resetPassword',
    path: '/reset-password/:token',
    component: ResetPasswordPage,
    layout: DefaultLayout,
    accessChecks: [userUnauthenticated],
  },
  {
    name: 'notFound',
    component: NotFound,
    layout: ErrorLayout,
  },
];

const ROUTE_MAP = ROUTES.reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export default ROUTE_MAP;

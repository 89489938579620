import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';

import { ERROR_MESSAGES, EXTERNAL_LINKS } from 'core/api/constants';
import { SET_ACCOUNT_CREDENTIALS } from 'core/api/apollo/mutations';
import { Checkbox, FormGroup, H4, Hint, Label, Link, ValidatedInput } from 'core/ui/atoms';
import { withForm } from 'core/ui/components';
import { userHasShippingAddress } from 'core/api/Account';

import { ContentButton, ContentForm, ContentSection } from 'onramp/ui/components';

@graphql(SET_ACCOUNT_CREDENTIALS)
class AccountCredentials extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
  };

  render() {
    const { currentUser: { email = '', password = '' }, mutate, onSuccess } = this.props;

    return (
      <AccountCredentialsForm
        currentUser={this.props.currentUser}
        initialData={{ email, password, acceptTerms: false }}
        onSubmit={mutate}
        onSuccess={onSuccess}
      />
    );
  }
}

@withForm('setAccountCredentials', {
  deriveVariablesFromFormData: (formData) => {
    const { acceptTerms, ...data } = formData;
    return { input: { ...data, passwordConfirmation: formData.password } };
  },
})
class AccountCredentialsForm extends PureComponent {
  minPwdLength = 8;
  maxPwdLength = 128;

  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  isValid = () => {
    const { form: { formData: { email = '',  password = '', acceptTerms = false } } } = this.props;
    return acceptTerms && email.length && password.length >= this.minPwdLength && password.length <= this.maxPwdLength;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { form: { handleSubmit } } = this.props;
    handleSubmit(this.isValid);
  };

  onCheckboxChange = (e) => this.props.form.handleChange({ [e.target.name]: e.target.checked });

  termsAndConditionsLabel = () => (
    <TermsAndConditionsLabel required>
      I've read and accept the{' '}
      <Link href={EXTERNAL_LINKS.termsAndConditions} rel='noreferrer' target='_blank'>
        terms & conditions
      </Link>
    </TermsAndConditionsLabel>
  );

  render() {
    const { form: { formData, onChange, isProcessing } } = this.props;

    return (
      <ContentForm
        onSubmit={this.onSubmit}
        noValidate
        instantValidate={false}
      >
        <ContentSection spaced highlight>
          <CreateAccountHeader>Create Account</CreateAccountHeader>
          <FormGroup>
            <Label required>Email Address</Label>
            <ValidatedInput
              type="email"
              placeholder="ex. hi@gomethodology.com"
              name="email"
              value={formData.email}
              onChange={onChange}
              validators={['required', 'isEmail']}
              errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.email]}
            />
          </FormGroup>
          <FormGroup>
            <Label required>Password</Label>
            <ValidatedInput
              name="password"
              onChange={onChange}
              value={formData.password}
              type="password"
              placeholder="ex. ********"
              validators={['required',
                `minStringLength:${this.minPwdLength}`,
                `maxStringLength:${this.maxPwdLength}`,
              ]}
              errorMessages={[
                ERROR_MESSAGES.required,
                ERROR_MESSAGES.passwordLength,
                ERROR_MESSAGES.passwordLength,
              ]}
            />
            <Hint>At least 8 characters</Hint>
          </FormGroup>
        </ContentSection>

        <FormGroup>
          <Checkbox
            name="acceptTerms"
            label={this.termsAndConditionsLabel()}
            checked={formData.acceptTerms}
            value={true}
            onChange={this.onCheckboxChange}
          />
        </FormGroup>
        <ContentButton type="submit" disabled={isProcessing || !this.isValid()}>
          {userHasShippingAddress(this.props.currentUser) ? 'Purchase Program' : 'Start Subscription'}
        </ContentButton>
      </ContentForm>
    );
  }
}

const CreateAccountHeader = styled(H4)`
  text-align: center;
  margin-bottom: 0;
`;

const TermsAndConditionsLabel = styled.span`
  &:after {
    content: '*';
    position: relative;
    color: ${(p) => p.theme.colors.danger};
    left: 5px;
  }
`;

export default AccountCredentials;

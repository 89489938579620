import React, { useEffect, useState, createContext, useCallback } from 'react';
import { storePromocode, getPartnerLink } from 'core/api/splitTestHelpers';

// TODO: Refactoring this to use a reducer and move helpers to seprate file
//Helper function
function setCookie(cname, cvalue) {
  document.cookie = cname + '=' + cvalue  + ';path=/; max-age=' + String(365*24*60*60);
}

function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function clearReferralCookie() {
  const referralCookies = getCookie('_methodology_sign_up');
  if (referralCookies) {
    const decodedCookie = JSON.parse(decodeURIComponent(referralCookies));
    delete decodedCookie.referral_code;
    setCookie('_methodology_sign_up', encodeURIComponent(JSON.stringify(decodedCookie)));
  }
}


// Context
export const ReferralCodeContext = createContext();
export const ReferralCodeConsumer = ReferralCodeContext.Consumer;

// eslint-disable-next-line react/prop-types
export const ReferralCodeContextProvider = ({ children }) => {
  const [referralCode, setReferralCode] = useState(null);
  const [partnerCode, setPartnerCode] = useState(false);

  useEffect(() => {
    getReferralCode();
    }, []);

  const getReferralCode = async () => {
    const referralCode = await storePromocode(window.location);
    const partnerLink = getPartnerLink(window.location);
    setReferralCode(referralCode);
    setPartnerCode(partnerLink);
  };

  const clearReferralCode = useCallback(() => {
    clearReferralCookie();
    setReferralCode(null);
  },[]);

  return (
    <ReferralCodeContext.Provider value={{ referralCode, clearReferralCode, partnerCode }}>
      {children}
    </ReferralCodeContext.Provider>
  );
};


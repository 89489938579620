import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import useReferralCode from '../../../core/hooks/useReferralCode';
import client from 'core/api/apollo/apolloClient';
import { accessChecksDisabledVar } from 'core/api/apollo/cache';
import Money from 'core/api/Money';
import { COMPLETE_PREPAID_PURCHASE } from 'core/api/apollo/mutations';
import Tracker from 'core/api/tracking/tracker';
import TikTokTracker from 'core/api/tracking/vendors/Tiktok';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

import { onrampNext } from 'onramp/api/navigation';
import { ROUTE_MAP } from 'onramp/api/routeSequence';
import {
  ContentSection,
  ContentButton,
  InfoHeader,
  InfoImage,
  OneTimeProgramSummary,
  Page,
  PageContent,
  PageInfo,
  PrepaidPurchaseSummary,
} from 'onramp/ui/components';
import AccountCredentials from 'onramp/ui/components/ReviewPage/AccountCredentials';
import ProcessingPurchaseOverlay from 'onramp/ui/components/ReviewPage/ProcessingPurchaseOverlay';

import PageImage from 'onramp/assets/images/Review/main.png';

function PrepaidReviewPage(props) {
  const { currentUser, cache } = props;
  const { clearReferralCode } = useReferralCode();

  useEffect (() => {
    return () => {
      clearReferralCode();
    };
  }, [clearReferralCode]);

  const onSuccess = () => {
    // NOTE: temporarily disable route access checks so the reset doesn't automatically
    //       redirect to the root path
    accessChecksDisabledVar(true);
    client.stop();
    client.resetStore().then(() => {
      onrampNext(currentUser, cache);
      accessChecksDisabledVar(false);
    });
  };

  return (
    <Page backgroundColor="#EFECE9">
      <PageInfo>
        <InfoHeader>Review your purchase</InfoHeader>
        <InfoImage
          src={PageImage}
          title='"Chickpea of the Sea" Niçoise Salad'
          subtitle="Picholine & Casteltravano Olives, Chioggia Beet, Haricot Vert, Thyme Roasted Marble Potatoes, Japanese Sweet Potatoes, Lemon Caper Flax Vinaigrette"
        />
      </PageInfo>
      <PageContent>
        <ContentSection spaced highlight>
          <OneTimeProgramSummary returnTo={ROUTE_MAP.prepaidReview.name} />
        </ContentSection>

        <ContentSection spaced highlight>
          <PrepaidPurchaseSummary enablePromoCodeForm={false} />
        </ContentSection>

        <CompletePurchaseForm
          currentUser={currentUser}
          onSuccess={onSuccess}
        />
      </PageContent>
    </Page>
  );
}

PrepaidReviewPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
};

function CompletePurchaseForm(props) {
  const { currentUser } = props;
  const { displayApolloResponseErrors } = useErrorHandlers();
  const [completePrepaidPurchase] = useMutation(COMPLETE_PREPAID_PURCHASE);
  const [isProcessing, setIsProcessing] = useState(false);
  const [backgroundJobId, setBackgroundJobId] = useState(null);
  const [purchase, setPurchase] = useState(null);

  const onSuccess = () => {
    const { email, phoneNumber, nextOneTimeProgram } = currentUser;
    const {  progId } = nextOneTimeProgram;
    const { bundleReservations, deliveryReservations, coupon, total } = purchase;

    const deliveryFee = deliveryReservations[0].deliveryFee.amount;
    const serviceFee = deliveryReservations[0].serviceFee.amount;
    const tiktokParams = {
      content_type: 'product',
      content_id: `prog-${progId}`,
      quantity: bundleReservations.length,
      price: ((bundleReservations[0].price.amount + serviceFee + deliveryFee) / 100),
      value: total.amount / 100,
      currency: 'USD',
    };

    if (!!coupon) {
      tiktokParams.query = coupon.code;
    }


    // eslint-disable-next-line no-console
    Tracker.trackPurchase({
      transactionId: Date.now().toString(),
      purchaseAmount: Money(purchase.total),
      orderInfo: tiktokParams,
    });

    TikTokTracker.events.subscription(email, phoneNumber, tiktokParams);
    props.onSuccess();
  };

  const onError = (errors) => {
    setIsProcessing(false);
    setBackgroundJobId(null);
    displayApolloResponseErrors(errors);
  };

  const onAccountCredentialSuccess = () => {
    setIsProcessing(true);
    return completePrepaidPurchase({ variables: { input: {} } }).then(({ data }) => {
      const { completePrepaidPurchase: { backgroundJob, purchase } } = data;
      setBackgroundJobId(backgroundJob.id);
      setPurchase(purchase);
    });
  };

  return (
    <Fragment>
     { !currentUser.reorder ? (<AccountCredentials
        currentUser={currentUser}
        onSuccess={onAccountCredentialSuccess}
      /> ) : (
        <ContentButton type="submit" onClick={onAccountCredentialSuccess}>
        {'Purchase Program'}
      </ContentButton>
      )}
      <ProcessingPurchaseOverlay
        open={isProcessing || backgroundJobId}
        backgroundJobId={backgroundJobId}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Fragment>
  );
}

CompletePurchaseForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PrepaidReviewPage;

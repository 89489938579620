import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import client from 'core/api/apollo/apolloClient';
import { SIGN_OUT } from 'core/api/apollo/mutations';
import { goToSignIn, signInPath } from 'core/api/actions/navigation';
import { session } from 'core/api/storage';
import { Button } from 'core/ui/atoms';
import useReferralCode from 'core/hooks/useReferralCode';

function LogoutButton(props) {
  const { clearReferralCode } = useReferralCode();
  const { children, reload=false, ...rest } = props;
  const onComplete = () => {
    session.clear();
    return client.resetStore().then(()=>{
      clearReferralCode();
      if (reload){
        window.location.href = signInPath();
      } else {
        goToSignIn();
      }
    });
  };

  const [logout] = useMutation(SIGN_OUT, {
    variables: { input: {} },
    onCompleted: onComplete,
    onError: onComplete,
  });

  return (
    <Button onClick={logout} {...rest}>{children || 'Log out'}</Button>
  );
}

LogoutButton.propTypes = {
  children: PropTypes.node,
  reload: PropTypes.bool,
};

export default LogoutButton;

import React, { PureComponent } from 'react';

import { Style, StyleSection } from 'admin/ui/atoms';
import Alert from 'core/ui/components/Alert';

const COPY = 'We promptly judged antique ivory buckles for the next prize.';

class Alerts extends PureComponent {
  render() {
    return (
      <StyleSection title='Alerts'>
        <Style name='Alert'>
          <Alert alert={{ message: COPY }} />
        </Style>
      </StyleSection>
    );
  }
}

export default Alerts;

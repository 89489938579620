import React from 'react';
import styled from 'styled-components';

import Header from 'core/ui/components/Header/Header';
import { NavItem, MobileNavItem } from 'core/ui/atoms';
import InviteFriends from 'dashboard/ui/components/InviteFriends';
import LogoutButton from 'core/ui/components/LogoutButton';

function DashboardHeader() {
  const leftNavItems = () => {
    return [
      <NavItem to="/" exact activeClassName="active" key="nav-home">Home</NavItem>,
      <NavItem to="/orders" exact activeClassName="active" key="nav-orders">Orders</NavItem>,
      <NavItem to="/settings" activeClassName="active" key="nav-settings">Settings</NavItem>,
    ];
  };

  const rightNavItems = () => {
    return [
      <InviteFriends id='header-invite-friends' key="nav-refer" btn-primary />,
      <LogoutButton btn-unstyled key="nav-sign-out">Log out</LogoutButton>,
    ];
  };

  const mobileNavItems = () => {
    return [
      <MobileNavItem to="/" exact activeClassName="active" key="nav-home">Home</MobileNavItem>,
      <MobileNavItem to="/orders" exact activeClassName="active" key="nav-orders">Orders</MobileNavItem>,
      <MobileNavItem to="/settings" activeClassName="active" key="nav-settings">Settings</MobileNavItem>,
      <MobileInviteFriends btn-unstyled key="nav-refer" />,
      <LogoutButton btn-unstyled key="nav-sign-out">Log out</LogoutButton>,
    ].filter((i) => i);
  };

  return (
    <Header leftNavItems={leftNavItems} rightNavItems={rightNavItems} mobileNavItems={mobileNavItems} />
  );
}

const MobileInviteFriends = styled(InviteFriends)`
  white-space: nowrap;
`;

export default DashboardHeader;

import ReactPixel from 'react-facebook-pixel';
import { facebookId, debugTrackingEnabled } from 'reactAppConstants';

if (facebookId) {
  const advancedMatching = {};
  const options = {
    autoConfig: true,
    debug: debugTrackingEnabled(),
  };

  ReactPixel.init(facebookId, advancedMatching, options);
  ReactPixel.fbq('dataProcessingOptions', []);
}

const logEvent = (event, payload) => {
  try {
    if (debugTrackingEnabled()) {
      console.debug(Tracker.name, event, payload, Tracker.enabled ? 'enabled' : 'disabled');
    }
  } catch(e) {
    console.error(Tracker.name, 'Error while logging', e, event, payload);
  }
};

const trackPageView = () => {
  if (Tracker.enabled) {
    ReactPixel.pageView();
  }
  logEvent('pageView');
};

const trackEvent = (event, payload) => {
  if (Tracker.enabled) {
    ReactPixel.track(event, payload);
  }
  logEvent(event, payload);
};

const Tracker = {
  name: 'Facebook',
  enabled: !!facebookId,
  events: {
    pageView: () => trackPageView(),
    lead: () => trackEvent('Lead'),
    registration: () => {},
    addToCart: () => {},
    applyPromoCode: () => {},
    checkoutProgress: () => {},
    purchase: () => {},
    error: () => {},
  },
};

export default Tracker;

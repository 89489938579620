import { getSizeFromBreakpoint } from 'core/ui/breakpoints';

export const flexChildMixin = (props) => {
  let rules = '';
  if (props.order) rules += `order: ${props.order};`;
  if (props.grow) rules += `flex-grow: ${props.grow};`;
  if (props.shrink) rules += `flex-shrink: ${props.shrink};`;
  return rules;
};

// FIXME: use the breakpoint helpers to build this css
export const hiddenMixin = (props) => {
  let res = '';
  if (props.hiddenXS) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('xs')}) {
        display: none !important;
      }
    `;
  }
  if (props.hiddenSM) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('md')}) {
        display: none !important;
      }
    `;
  }
  if (props.hiddenMD) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('md')}) {
        display: none !important;
      }
    `;
  }
  if (props.hiddenLG) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('lg')}) {
        display: none !important;
      }
    `;
  }
  return res;
};

const getGridRelatedValue = (gridSize, value) => {
  if (value && typeof value === 'number') {
    return `${(value / gridSize) * 100}%`;
  }
  return value;
};

// FIXME: replace with a real responsive grid framework like Bootstrap or Flexbox grid
export const buildResponsiveWidthMixin = (props) => {
  let res = '';

  // FIXME: maybe this should be min-width: ${getSizeFromBreakpoint('lg')}?
  if (props.lg != null) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('lg')}) {
        width: ${getGridRelatedValue(props.theme.layout.gridSize, props.lg)};
      }
    `;
  }

  // FIXME: what is going on with the max-width here? is it just a default so the sm and xs work w/o declared props?
  if (props.md != null) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('md')}) {
        width: ${getGridRelatedValue(props.theme.layout.gridSize, props.md)};
      }
      @media (max-width: ${getSizeFromBreakpoint('md')}) {
        width: 100%;
      }
    `;
  }

  if (props.sm != null) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('md')}) {
        width: ${getGridRelatedValue(props.theme.layout.gridSize, props.sm)};
      }
    `;
  }

  if (props.xs != null) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('xs')}) {
        width: ${getGridRelatedValue(props.theme.layout.gridSize, props.xs)};
      }
    `;
  }

  if (props.lgOffset != null) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('lg')}) {
        margin-left: ${getGridRelatedValue(props.theme.layout.gridSize, props.lgOffset)};
      }
    `;
  }

  if (props.mdOffset != null) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('md')}) {
        margin-left: ${getGridRelatedValue(props.theme.layout.gridSize, props.mdOffset)};
      }
    `;
  }

  if (props.smOffset != null) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('md')}) {
        margin-left: ${getGridRelatedValue(props.theme.layout.gridSize, props.smOffset)};
      }
    `;
  }

  if (props.xsOffset != null) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('xs')}) {
        margin-left: ${getGridRelatedValue(props.theme.layout.gridSize, props.xsOffset)};
      }
    `;
  }

  return res;
};

export const userHasSupportedAddress = (user) => new Account(user).hasSupportedAddress();

export const userHasCourierAddress = (user) => new Account(user).hasCourierAddress();

export const userHasShippingAddress = (user) => new Account(user).hasShippingAddress();

export const userHasPreSubscribedAccountType = (user) => new Account(user).hasPreSubscribedAccountType();

export const userHasShippingAccountType = (user) => new Account(user).hasShippingAccountType();

class Subscription {
  constructor(subscription) {
    this.subscription = subscription || {};
  }

  isFuture = () => this.subscription.status === 'future';

  isActive = () => !!this.subscription.activationDate && !this.isCancelled();

  isCancelled = () => !!this.subscription.cancellationDate;
}

class BillingAccount {
  constructor(billingAccount) {
    this.billingAccount = billingAccount || {};
  }

  isActive = () => !!this.billingAccount.active;

  isDoNotCharge = () => !!this.billingAccount.doNotCharge;
}

class Account {
  constructor(user) {
    this.user = user || {};
    this.subscription = new Subscription(this.user.subscription);
    this.billingAccount = new BillingAccount(this.user.billingAccount);
  }

  hasSupportedAddress = () => !!(this.user.primaryAddress && this.user.primaryAddress.supported);

  hasCourierAddress = () => !!((this.user.primaryAddress && this.user.primaryAddress.deliveryOption && this.user.primaryAddress.deliveryOption.deliveryMethod === 'COURIER') && this.user.userAccountType !== 'shipping');

  hasShippingAddress = () => !this.hasCourierAddress() || this.user.userAccountType === 'shipping';

  hasFeatureFlag = (name) => {
    const { featureFlags = [] } = this.user;
    const feature = featureFlags.find((feature) => feature.name === name);
    return feature && feature.enabled;
  }

  hasPreSubscribedAccountType = () => !!(this.user.userAccountType === 'pre_subscribed')

  hasShippingAccountType = () => !!(this.user.userAccountType === 'shipping')

  hasEmptyAccountType = () => !(this.user.userAccountType)
}

export default Account;

import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StepForm as CustomizePlanSubForm } from './CustomizePlanPage';
import { StepForm as PrepaidBundleForm } from './PrepaidBundlePage';
import { PROGRAM_SCHEDULE_VALUES } from 'onramp/constants';
import {
  FormGroup,
  InputGroupLabel,
  Radio,
  Hint,
} from 'core/ui/atoms';
import PageImage from 'onramp/assets/images/CustomizePlan/main.png';
import {
  InfoDescription,
  InfoHeader,
  InfoImage,
  Page,
  PageInfo,
  PageContent,
} from 'onramp/ui/components';
import { onrampNext } from 'onramp/api/navigation';

const PROGRAM_SCHEDULE_OPTIONS = [
  {
    label: 'Weekly Subscription',
    value: PROGRAM_SCHEDULE_VALUES.COURIER,
    description: 'Save up to 15%, swap meals, access full menu',
    success: true,
  },
  {
    label: 'One-Time Program',
    value: PROGRAM_SCHEDULE_VALUES.SHIPPING,
    description: 'Curated programs for optimal results', // FIXME: swap
  },
];
function PreSubscribedPlanPage(props) {
  // const { referralCode } = useReferralCode();
  // const isLia10 = referralCode && (referralCode.code === 'LIA10');

  const defaultAccType = PROGRAM_SCHEDULE_VALUES.COURIER;
  const [userAccType, setUserAccType] = useState(defaultAccType);

  const onSuccess = () => {
      onrampNext(props.currentUser, props.cache, `?userAccountType=${userAccType}`);
  };

  return (
    <Fragment>
      <Page backgroundColor="#E7EBE9">
        <PageInfo>
          <InfoHeader>Let’s get to the food!!!</InfoHeader>
          <InfoDescription>Choose your program options.</InfoDescription>
          <InfoImage
            src={PageImage}
            title="Wild Rice Quinoa Harvest Bowl + Sweet Potato Cakes"
            subtitle="Turmeric Tahini, Balsamic Roasted Brussels Sprouts & Apples, Pomegranate Hazelnut Delicata Squash, Micro Mint"
          />
        </PageInfo>

        <PageContent>
          <CustomFormGroup>
            <InputGroupLabel>Program Schedule</InputGroupLabel>
            {PROGRAM_SCHEDULE_OPTIONS.map((option) => (
              <section key={`program-schedule-${option.value}`}>
                <CustomRadio
                  key={`program-schedule-input-${option.value}`}
                  name="accountType"
                  label={option.label}
                  value={option.value}
                  checked={!!userAccType && userAccType === option.value}
                  onChange={(e) => setUserAccType(e.target.value)}
                />
                {option.description && (
                  <InputGroupSublabel color={option.color} success={option.success} key={`program-schedule-description-${option.value}`} >{option.description} </InputGroupSublabel>
                )}
              </section>
            ))}
          </CustomFormGroup>

          {userAccType === PROGRAM_SCHEDULE_VALUES.COURIER ? (
            <CustomizePlanSubForm
              onSuccess={onSuccess}
              currentUser={props.currentUser}
              cache={props.cache}
            />
          ) : (
            <PrepaidBundleForm
              onSuccess={onSuccess}
              currentUser={props.currentUser}
              cache={props.cache}
            />
          )}
        </PageContent>
      </Page>
    </Fragment>
  );
}

const CustomRadio = styled(Radio)`
  margin-bottom: 0em;
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 0;
`;


const InputGroupSublabel = styled(Hint)`
  font-style: italic;
  padding-bottom: 2em;
  padding-left: 1.25em;
`;

PreSubscribedPlanPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
};

export default PreSubscribedPlanPage;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { isMenuOpenVar } from 'core/api/apollo/cache';
import { List, ListItem, WordMark as WordMarkAtom } from 'core/ui/atoms';

class MobileNav extends PureComponent {
  static propTypes = {
    opened: PropTypes.bool,
    navItems: PropTypes.arrayOf(PropTypes.object),
  };

  toggleMenu = (e) => {
    e.preventDefault();
    isMenuOpenVar(!isMenuOpenVar());
  };

  render() {
    const { opened, navItems } = this.props;

    return (
      <MobileNavOverlay opened={opened}>
        <Heading>
          <WordMark inverse />
        </Heading>
        <Body>
          <Nav>
            <List unstyled>
              {(navItems || []).map((item, index) => (
                <ListItem key={`mobile-nav-${index}-${Math.random()}`} onClick={this.toggleMenu}>
                  {item}
                </ListItem>
              ))}
            </List>
          </Nav>
        </Body>
      </MobileNavOverlay>
    );
  }
}

const MobileNavOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.overlays.backdropColor};
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  transition: all 0.3s;
  ${(p) => p.opened && css`
    opacity: 1;
    visibility: visible;
  `};
`;

const Heading = styled.header`
  position: relative;
  width: 100%;
  height: ${(p) => p.theme.layout.headerHeight};
`;

const Body = styled.div`
  padding: ${(p) => p.theme.layout.mobilePadding};
`;

const Nav = styled.nav`
  a, button {
    display: block;
    text-decoration: none;
    padding: 0.25em 0.5em;
    margin-right: 2em;
    font-size: ${(p) => p.theme.typography.sizes.h1};
    color: rgba(255, 255, 255, 0.8);
    &.active {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const WordMark = styled(WordMarkAtom)`
  position:absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
`;

export default MobileNav;

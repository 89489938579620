import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';

import { ERROR_MESSAGES } from 'core/api/constants';
import { SET_ZIPCODE_BY_EMAIL } from 'core/api/apollo/mutations';
import SIGN_UP_IMAGE from 'core/assets/images/bg1.jpg';
import cacheUpdater, { updateUserQuery } from 'core/api/cache/updaters';
import Tracker from 'core/api/tracking/tracker';
import withForm from 'core/ui/components/withForm';
import handlesErrors from 'core/ui/components/HandlesErrors';

import { onrampNext } from 'onramp/api/navigation';
import { FormGroup, Label, ValidatedInput, RouterLink } from 'core/ui/atoms';
import { ContentButton, ContentForm, InfoHeader, InfoImage, Page, PageContent, PageInfo } from 'onramp/ui/components';
import PageImage from 'onramp/assets/images/SignUp/main.png';
import { isViewportAbove } from 'core/ui/breakpoints';
import { cacheImages } from 'core/api/utils';


// NOTE: update the current user query so the auth checks work
@graphql(SET_ZIPCODE_BY_EMAIL, {
  options: {
    update: cacheUpdater('setZipcodeByEmail', updateUserQuery),
  },
})
@handlesErrors
class SignInExistingUserPage extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    cache: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
  };

  onSuccess = () => {
    onrampNext(this.props.currentUser, this.props.cache);
  };


  componentDidMount() {
    Tracker.trackLead();
  }

  render() {
    const { mutate } = this.props;

    return (
      <Page backgroundColor="#A4B4AE">
        <CustomPageInfo>
          <CustomInfoHeader text-color='inverse'>Let's see if we deliver to you</CustomInfoHeader>
          <CustomInfoImage
            src={PageImage}
            title="Miso Ginger Soba Bowl + Shiitake Chicken"
            subtitle="Ginger Miso Carrot Puree, Hijiki Salad, Pickled Burdock, Edamame, Watermelon Radish, Black Rice Soba Noodle"
          />
        </CustomPageInfo>
        <CustomPageContent>
          <StepForm
            onSubmit={mutate}
            onSuccess={this.onSuccess}
          />
        </CustomPageContent>
      </Page>
    );
  }
}

@withForm('setZipcodeByEmail')
class StepForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  };

  isValid = () => {
    const { form: { formData: { zipcode, email } } } = this.props;
    return zipcode && email;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { form: { handleSubmit } } = this.props;
    handleSubmit(this.isValid);
  };

  render() {
    const { form: { formData, onChange, isProcessing } } = this.props;

    return (
      <ContentForm
        onSubmit={this.onSubmit}
        noValidate
        instantValidate={false}
      >
        <FormGroup>
          <Label text-color='inverse' required>Zip Code</Label>
          <ValidatedInput
            inverse
            type="text"
            name="zipcode"
            placeholder="Type zip code"
            maxLength={5}
            value={formData.zipcode}
            onChange={onChange}
            validators={['required', 'isNumber', 'minStringLength:5']}
            errorMessages={[
              ERROR_MESSAGES.required,
              ERROR_MESSAGES.numeric,
              ERROR_MESSAGES.zip,
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Label text-color='inverse' required>Email Address</Label>
          <ValidatedInput
            inverse
            type="email"
            placeholder="Type email address"
            name="email"
            value={formData.email}
            onChange={onChange}
            validators={['required', 'isEmail']}
            errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.email]}
          />
        </FormGroup>

        <SubmitButtonCont>
          <ContentButton type="submit" disabled={!this.isValid() || isProcessing}>
          Start my journey
          </ContentButton>

          <SignUpLink to="/sign-up" onMouseEnter={cacheSignupImage}>
                  Not a member? Sign up!
                </SignUpLink>
        </SubmitButtonCont>
      </ContentForm>

    );
  }
}

const cacheSignupImage = () => {
  if (isViewportAbove('md')) {
    cacheImages(SIGN_UP_IMAGE.image);
  }
};

const CustomPageInfo = styled(PageInfo)`
  background: ${(p) => p.theme.colors.primary};
  position: relative;

  svg.burst {
    path {
      fill: ${(p) => p.theme.colors.white};
    }
  }

  ${(p) => p.theme.min('md')`
    margin-bottom: 50vh;

    svg.burst {
      top: 70%;
      left: 15%;
    }
  `}
`;

const CustomInfoHeader = styled(InfoHeader)`
  color: ${(p) => p.theme.colors.white};
  font-size: 3.6em;
  ${(p) => p.theme.max('md')`
    font-size: 2.5em;
  `}
`;

const CustomInfoImage = styled(InfoImage)`
  ${(p) => p.theme.min('md')`
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translate(-50%, 50%);
  `}
`;

const CustomPageContent = styled(PageContent)`
  background: ${(p) => p.theme.colors.primary};
  ${(p) => p.theme.min('md')`
    margin-bottom: 50vh;
  `}
`;

const SubmitButtonCont = styled.div`
  text-align: center;
  margin-top: 12vh;
  ${(p) => p.theme.max('md')`
    margin-top: 4vh;
    margin-bottom: ${p.theme.layout.headerHeight};
  `}
`;

const SignUpLink = styled(RouterLink)`
  color: white;
`;

export default SignInExistingUserPage;

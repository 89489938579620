import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';

import { LIST_DIETS } from 'core/api/apollo/queries';
import { SET_DIET_AND_ALLERGIES } from 'core/api/apollo/mutations';
import { onrampNext } from 'onramp/api/navigation';

import { FormGroup, InputGroupLabel, Radio, Text } from 'core/ui/atoms';
import { withForm, Loading } from 'core/ui/components';
import FoodsToAvoidInput from 'core/ui/components/FoodsToAvoidInput';

import {
  ContentButton,
  ContentForm,
  InfoHeader,
  InfoImage,
  Page,
  PageContent,
  PageInfo,
} from 'onramp/ui/components';

import PageImage from 'onramp/assets/images/Diet/main.png';

@graphql(SET_DIET_AND_ALLERGIES)
class DietPage extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    cache: PropTypes.object.isRequired,
  };

  onSuccess = () => onrampNext(this.props.currentUser, this.props.cache);

  render() {
    const { currentUser, mutate } = this.props;

    const dietId = currentUser.diet && currentUser.diet.id;
    const allergyIds = (currentUser.allergyTags || []).map((tag) => tag.id);
    const initialData = {
      setDietInput: { dietId },
      setFoodsToAvoidInput: { allergyIds },
    };

    return (
      <Page backgroundColor="#FFF8F0">
        <PageInfo>
          <InfoHeader>What do you eat?</InfoHeader>
          <InfoImage
            src={PageImage}
            title="Pastured Za'atar Lamb Meatballs + Jeweled Quinoa"
            subtitle='Brussels Sprouts & Delicata Squash, Herbed White Beans & Lentils, Beetroot Tahini, Cranberry Relish, Vegan "Feta"'
          />
        </PageInfo>
        <PageContent>
          <StepForm
            initialData={initialData}
            onSubmit={mutate}
            onSuccess={this.onSuccess} />
        </PageContent>
      </Page>
    );
  }
}

@graphql(LIST_DIETS)
@withForm('setDietAndAllergies', {
  deriveVariablesFromFormData: (formData, { data: { listDiets } }) => {
    const diet = listDiets.find((diet) => diet.id === formData.setDietInput.dietId);
    const foodsToAvoidIds = uniq([...formData.setFoodsToAvoidInput.allergyIds, ...diet.ingredientTags.map((tag) => tag.id)]);
    return merge({}, formData, { setFoodsToAvoidInput: { foodsToAvoidIds } });
  },
})
class StepForm extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  isValid = () => !!this.props.form.formData.setDietInput.dietId;

  onDietChanged = (dietId) => { this.props.form.handleChange({ setDietInput: { dietId } }); }

  onFTAChanged = (allergyIds) => { this.props.form.handleChange({ setFoodsToAvoidInput: { allergyIds } }); }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.handleSubmit(this.isValid);
  };

  render() {
    const {
      data: { loading, listDiets },
      form: { isProcessing, formData: { setDietInput: { dietId }, setFoodsToAvoidInput: { allergyIds } } },
    } = this.props;

    if (loading) { return <Loading />; }

    const diets = sortBy(listDiets, 'position');

    const disabled = !dietId;

    return (
      <ContentForm onSubmit={this.onSubmit} noValidate instantValidate={false}>
        <FormGroup>
          <InputGroupLabel>I'd like my meals to be...</InputGroupLabel>
          <DietRadios>
            {diets.map((diet) => (
              <RadioButton
                name='dietId'
                label={diet.name}
                checked={dietId === diet.id}
                onChange={() => this.onDietChanged(diet.id)}
                value={diet.id}
                key={diet.id}
              />
            ))}
          </DietRadios>
        </FormGroup>

        <FormGroup>
          <InputGroupLabel>Any allergies?</InputGroupLabel>
          <FTASectionDescription>
            We'll make sure your meals don't contain ingredients you're allergic to.
          </FTASectionDescription>
          <CustomFTAInput
            initialFoodsToAvoidIds={allergyIds}
            placeholder='ex. Apricots'
            onChange={this.onFTAChanged}
          />
        </FormGroup>
        <ContentButton type="submit" disabled={isProcessing || disabled}>
          Continue
        </ContentButton>
      </ContentForm>
    );
  }
}

const DietRadios = styled.div`
  margin: 2vh auto 4vh auto;
`;

const FTASectionDescription = styled(Text)`
  text-align: left;
`;

const CustomFTAInput = styled(FoodsToAvoidInput)`
  margin-bottom: 4vh;
`;

const RadioButton = styled(Radio)`
  margin-bottom: 1em;
`;

export default DietPage;

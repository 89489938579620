import React, { PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'lodash/debounce';

import useProductSizes from 'core/hooks/useProductSizes';
import useSubscriptionOptions from 'core/hooks/useSubscriptionOptions';
import useUser from 'core/hooks/useUser';

import { SET_SUBSCRIPTION_OPTIONS , CREATE_SUBSCRIPTION_QUANTITIES } from 'core/api/apollo/mutations';
import { ERROR_MESSAGE } from 'core/api/constants';
import Money from 'core/api/Money';
import Tracker from 'core/api/tracking/tracker';

import { Checkbox, FormGroup, InputGroupLabel, Radio, Text } from 'core/ui/atoms';
import { displayDate } from 'core/ui/dateHelpers';
import { Loading } from 'core/ui/components';
import handlesErrors from 'core/ui/components/HandlesErrors';

import { FOOD_TYPES, NUM_DAY_OPTIONS, ESTIMATE_MIN_AMOUNT, ESTIMATE_ERROR_AMOUNT } from 'onramp/constants';
import { numDaysLabel, productSizeLabel } from 'onramp/helpers';
import { ContentButton, ContentForm } from 'onramp/ui/components';

const SUBSCRIPTION_OPTIONS_DEBOUNCE = 500; // ms

function CustomizePlanForm(props) {
  const { onSubmit, onSuccess, disabled } = props;
  const { currentUser } = useUser();
  const { loading, subscriptionOptions = [] } = useSubscriptionOptions();

  return (
    loading ? (
      <Loading />
    ) : (
      <StepForm
        currentUser={currentUser}
        initialData={{ ...subscriptionOptions, productSizeId: subscriptionOptions.productSize.id }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        disabled={disabled}
      />
    )
  );
}

CustomizePlanForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

@handlesErrors
@graphql(CREATE_SUBSCRIPTION_QUANTITIES, { name: 'createSubscriptionQuantities' })
@graphql(SET_SUBSCRIPTION_OPTIONS, { name: 'setSubscriptionOptions' })
class StepForm extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    initialData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    createSubscriptionQuantities: PropTypes.func.isRequired,
    setSubscriptionOptions: PropTypes.func.isRequired,
    displayApolloResponseErrors: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      initialData: { breakfast, lunch, dinner, snacks, numDays, productSizeId, productSize, estimatedPrice, subscribedDays, startDay },
    } = this.props;

    // set default state
    this.state = {
      breakfast,
      lunch,
      dinner,
      snacks,
      numDays,
      productSizeId,
      productSize,
      estimatedPrice,
      subscribedDays,
      startDay,
      isProcessing: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setStateSafely = (...args) => this.mounted && this.setState(...args);

  isValid = () => {
    const { breakfast, lunch, dinner, snacks, numDays, productSizeId } = this.state;
    return (breakfast || lunch || dinner || snacks) && numDays && productSizeId;
  };

  updateSubscriptionOptions = () => {
    this.setStateSafely({ isProcessing: true });

    this.setSubscriptionOptions();
  }

  setSubscriptionOptions = debounce(() => {
    const { isProcessing, isSaving, productSize, estimatedPrice, subscribedDays, startDay, ...input } = this.state;
    const { setSubscriptionOptions } = this.props;

    if (!this.mounted || isSaving) {
      return;
    }

    if (!this.isValid()) {
      this.setStateSafely({
        productSize: null,
        estimatedPrice: null,
        subscribedDays: null,
        startDay: null,
        isProcessing: false,
      });
      return;
    }

    this.setStateSafely({
      productSize: null,
      estimatedPrice: null,
      subscribedDays: null,
      startDay: null,
      isProcessing: true,
    });

    setSubscriptionOptions({ variables: { input } })
    .then(({ data: { setSubscriptionOptions: { subscriptionOptions, errors } } }) => {
      if (errors.length) {
        this.handleError(errors);
      } else {
        const { productSize, estimatedPrice, subscribedDays, startDay } = subscriptionOptions;
        this.setStateSafely({ productSize, estimatedPrice, subscribedDays, startDay });
      }
    }, () => {
      this.handleError([{ message: ERROR_MESSAGE }], true);
    })
    .finally(() => {
      this.setStateSafely({ isProcessing: false });
    });
  }, SUBSCRIPTION_OPTIONS_DEBOUNCE);

  handleError = (errors, displayOnly = false) => {
    this.setStateSafely({ isProcessing: false, isSaving: false });
    this.props.displayApolloResponseErrors(errors, displayOnly);
  };

  setField = (name, value) => this.setStateSafely({ [name]: value }, this.updateSubscriptionOptions);

  numDaysLabel = (option) => {
    const { currentUser: { primaryAddress: { deliveryOption: { deliveryMethod } } } } = this.props;
    const label = numDaysLabel(option.value, deliveryMethod);

    return `${label.label} (${label.range})`;
  };

  displayPriceRange = () => {
    const { isProcessing, estimatedPrice } = this.state;
    if (isProcessing) {
      return 'Calculating...';
    }

    const estimate = Money(estimatedPrice).getAmount();
    const min = Math.max(estimate - ESTIMATE_ERROR_AMOUNT, ESTIMATE_MIN_AMOUNT);
    const max = estimate + ESTIMATE_ERROR_AMOUNT;
    return `${Money({ amount: min }).toFormat('$0,0')} - ${Money({ amount: max }).toFormat('$0,0')}`;
  };

  displayDeliveries = () => {
    const { subscribedDays } = this.state;
    if (!(subscribedDays || []).length) {
      return '...';
    }
    return `${subscribedDays.length > 1 ? 'Two deliveries' : 'One delivery'} per week:`;
  }

  displayDeliveryTimes = () => {
    const { currentUser: { primaryAddress: { deliveryOption: { name } } } } = this.props;
    const { subscribedDays } = this.state;
    const days = (subscribedDays || []).map((day) => `${moment().day(day).format('dddd')}s`);

    return (name && days.length) ? `${name} on ${days.join(' and ')}` : '...';
  };

  onSuccess = () => {
    const { breakfast, lunch, dinner, snacks, numDays, productSize, estimatedPrice } = this.state;
    Tracker.stashSubscriptionDetails({ breakfast, lunch, dinner, snacks, numDays, productSize, estimatedPrice: Money(estimatedPrice).toFormat('0.00') });
    const { email, phoneNumber } = this.props.currentUser;
    Tracker.trackAddToCart({ email, phoneNumber });
    this.props.onSuccess();
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.isValid() || this.state.isProcessing || this.props.disabled) {
      return;
    }

    this.setStateSafely({ isSaving: true });
    this.props.onSubmit();
    return this.props.createSubscriptionQuantities({
      variables: { input: { } },
    }).then(({ data: { createSubscriptionQuantities: { errors } } }) => (
      errors.length ? this.handleError(errors) : this.onSuccess()
    )).finally(() => {
      this.setStateSafely({ isSaving: false });
    });
  };

  render() {
    const { isProcessing, isSaving } = this.state;
    const { disabled } = this.props;

    return (
      <ContentForm onSubmit={this.onSubmit} noValidate instantValidate={false}>
        <FormGroup>
          <InputGroupLabel>What sounds tasty?</InputGroupLabel>
          {FOOD_TYPES.map((type) => (
            <CustomCheckbox
              key={type.value}
              name="foodType"
              label={type.name}
              checked={this.state[type.value]}
              checkedIcon='minus'
              uncheckedIcon='plus'
              onChange={() => this.setField(type.value, !this.state[type.value])}
            />
          ))}
        </FormGroup>

        <FormGroup>
          <InputGroupLabel>How many days would you like food?</InputGroupLabel>
          {NUM_DAY_OPTIONS.map((option) => (
            <CustomRadio
              key={`day-${option.value}`}
              name="numDays"
              label={this.numDaysLabel(option)}
              value={option.value}
              checked={this.state.numDays === option.value}
              onChange={() => this.setField('numDays', option.value)}
            />
          ))}
        </FormGroup>

        <ProductSizeRadios
          selected={this.state.productSizeId}
          onChange={this.setField}
        />

        <Summaries>
          <SummaryLabel data-deliveries>{this.displayDeliveries()}</SummaryLabel>
          <SummaryValue data-delivery-times>{this.displayDeliveryTimes()}</SummaryValue>

          <SummaryLabel>First delivery date:</SummaryLabel>
          <SummaryValue data-first-delivery-date>
            {this.state.startDay ? displayDate(this.state.startDay.date, 'deliveryDateUpdated') : '...'}
          </SummaryValue>
        </Summaries>

        <Notice text-size='smaller'>
          Not home during your delivery window? Not to worry. We pack your food with ice to keep it cool all day. Pop it in the fridge when you get home.
        </Notice>

        <CustomContentButton type="submit" disabled={!this.isValid() || isProcessing || isSaving || disabled}>
          <PriceRange data-price-estimate>
            {this.displayPriceRange()} <small>per week</small>
          </PriceRange>
          <div>Continue</div>
        </CustomContentButton>
      </ContentForm>
    );
  }
}

function ProductSizeRadios(props) {
  const { selected, onChange } = props;
  const { loading, productSizes } = useProductSizes();

  if (loading) {
    return <Loading />;
  }

  return (
    <FormGroup>
      <InputGroupLabel>How much food?</InputGroupLabel>
      {productSizes.map((size) => (
        <CustomRadio
          key={size.id}
          name="productSizeId"
          label={productSizeLabel(size)}
          value={size.id}
          checked={selected === size.id}
          onChange={() => onChange('productSizeId', size.id)}
        />
      ))}
    </FormGroup>
  );
}

ProductSizeRadios.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CustomRadio = styled(Radio)`
  margin-bottom: 1em;
`;

const CustomCheckbox = styled(Checkbox)`
  margin-bottom: 1em;
`;

const Summaries = styled.div`
  margin-top: 20px;
`;

const SummaryLabel = styled(Text)`
  font-size: 1.15em;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.25em;
`;

const SummaryValue = styled(Text)`
  margin-top: 0;
`;

const Notice = styled(Text)`
  margin: 3vh 0;
`;

const CustomContentButton = styled(ContentButton)`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
`;

const PriceRange = styled.div`
  flex-grow: 2;
  border-right: 1px solid ${(p) => p.theme.colors.black};
  padding-right: 1em;
  margin-right: 1em;
  small {
    display: block;
  }
`;

export default CustomizePlanForm;

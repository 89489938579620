import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AppRoute from 'core/ui/components/AppRoute';
import CORE_ROUTES from 'core/api/routes';
import ONRAMP_ROUTES from 'onramp/api/routes';
import DASHBOARD_ROUTES from 'dashboard/api/routes';
import SETTINGS_ROUTES from 'settings/api/routes';
import ADMIN_ROUTES from 'admin/api/routes';

class MainRoutes extends Component {
  render() {
    return (
      <Switch>
        <Redirect exact from="/sign-up" to="/onramp/sign-up" />
        <AppRoute exact {...CORE_ROUTES.signIn} />
        <AppRoute exact {...CORE_ROUTES.forgotPassword} />
        <AppRoute exact {...CORE_ROUTES.resetPassword} />

        <Redirect exact from='/onramp/motivation' to='/onramp/about-you' />
        <Redirect exact from='/onramp/amount-of-food' to='/onramp/customize-plan' />
        <AppRoute exact {...ONRAMP_ROUTES.signUp} />
        {/* //This is used to sign in users who have not created any orders or passwords. */}
        <AppRoute exact {...ONRAMP_ROUTES.signIn} />
        <AppRoute exact {...ONRAMP_ROUTES.aboutYou} />
        <AppRoute exact {...ONRAMP_ROUTES.prepaidBundle} />
        <AppRoute exact {...ONRAMP_ROUTES.diet} />
        <AppRoute exact {...ONRAMP_ROUTES.customizePlan} />
        <AppRoute exact {...ONRAMP_ROUTES.preSubscribedPlan} />
        <AppRoute exact {...ONRAMP_ROUTES.shippingAddress} />
        <AppRoute exact {...ONRAMP_ROUTES.billingInfo} />
        <AppRoute exact {...ONRAMP_ROUTES.review} />
        <AppRoute exact {...ONRAMP_ROUTES.prepaidReview} />
        <AppRoute exact {...ONRAMP_ROUTES.prepaidConfirmation} />
        <AppRoute exact {...ONRAMP_ROUTES.outOfDeliveryZone} />

        <Redirect exact from="/deliveries" to="/orders" />
        <Redirect exact from="/manage/:deliveryDayId" to="/cart/:deliveryDayId" />
        <AppRoute exact {...DASHBOARD_ROUTES.root} />
        <AppRoute exact {...DASHBOARD_ROUTES.orders} />
        <AppRoute exact {...DASHBOARD_ROUTES.cart} />
        <AppRoute exact {...DASHBOARD_ROUTES.defaultMenu} />
        <AppRoute exact {...DASHBOARD_ROUTES.menu} />
        <AppRoute exact {...DASHBOARD_ROUTES.menuCategory} />
        <AppRoute exact {...DASHBOARD_ROUTES.menuFilter} />
        <AppRoute exact {...DASHBOARD_ROUTES.menuReorder} />
        <AppRoute exact {...DASHBOARD_ROUTES.menuProduct} />

        <Redirect from="/account" to="/settings" />
        <Redirect exact from="/settings" to="/settings/account-info" />
        <Redirect exact from="/settings/notifications" to="/settings/account-info" />
        <AppRoute exact {...SETTINGS_ROUTES.accountInfo} />
        <AppRoute exact {...SETTINGS_ROUTES.billing} />
        <AppRoute exact {...SETTINGS_ROUTES.contact} />
        <AppRoute exact {...SETTINGS_ROUTES.subscription} />
        <AppRoute exact {...SETTINGS_ROUTES.foodPreferences} />

        <AppRoute exact {...ADMIN_ROUTES.styleGuide} />

        <AppRoute {...CORE_ROUTES.notFound} />
      </Switch>
    );
  }
}

export default MainRoutes;

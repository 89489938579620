import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import { GENDERS } from 'core/api/constants';
import { LIST_MOTIVATIONS } from 'core/api/apollo/queries';
import { SET_GENDER_AND_MOTIVATION } from 'core/api/apollo/mutations';
import { withForm, Loading } from 'core/ui/components';
import { FormGroup, InputGroupLabel, Radio } from 'core/ui/atoms';

import { onrampNext } from 'onramp/api/navigation';
import PageImage from 'onramp/assets/images/AboutYou/main.png';

import {
  ContentButton,
  ContentForm,
  InfoDescription,
  InfoHeader,
  InfoImage,
  Page,
  PageInfo,
  PageContent,
} from 'onramp/ui/components';

@graphql(SET_GENDER_AND_MOTIVATION)
class AboutYouPage extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    cache: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
  };

  onSuccess = () => {
    onrampNext(this.props.currentUser, this.props.cache);
  };

  render() {
    const { currentUser, mutate } = this.props;

    const initialData = {
      gender: currentUser && currentUser.gender,
      motivationId: currentUser.motivation && currentUser.motivation.id,
    };

    return (
      <Page backgroundColor="#E7EBE9">
        <PageInfo hideBackButton>
          <InfoHeader>Hooray, we deliver to you!</InfoHeader>
          <InfoDescription>Now tell us about yourself.</InfoDescription>
          <InfoImage
            src={PageImage}
            title="Turkey & Lentil Bolognese + Sprouted Walnut Pesto Pasta"
            subtitle="Thyme Beech Mushrooms, Onion Puffed Rice, Baby Red Russian Kale, Fresh Basil"
          />
        </PageInfo>
        <PageContent>
          <StepForm
            initialData={initialData}
            onSubmit={mutate}
            onSuccess={this.onSuccess}
          />
        </PageContent>
      </Page>
    );
  }
}

@graphql(LIST_MOTIVATIONS)
@withForm('setGenderAndMotivation', {
  deriveVariablesFromFormData: ({ gender, motivationId }) => ({
    setGenderInput: { gender },
    setMotivationInput: { motivationId },
  }),
})
class StepForm extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  isValid = () => !!this.props.form.formData.gender && !!this.props.form.formData.motivationId;

  onSubmit = (e) => {
    e.preventDefault();
    const { form: { handleSubmit } } = this.props;
    handleSubmit(this.isValid);
  };

  render() {
    const {
      data: { loading, listMotivations: motivations },
      form: { formData, onChange, isProcessing },
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <ContentForm onSubmit={this.onSubmit} noValidate instantValidate={false}>
        <FormGroup role="radiogroup" aria-labelledby="genderIdentity">
          <InputGroupLabel id="genderIdentity">What gender do you identify with?</InputGroupLabel>
          {GENDERS.map((gender) => (
            <RadioButton
              name="gender"
              label={gender.title}
              value={gender.value}
              onChange={onChange}
              checked={formData.gender === gender.value}
              key={gender.value}
            />
          ))}
        </FormGroup>

        <FormGroup role="radiogroup" aria-labelledby="dietMotivation">
          <InputGroupLabel id="dietMotivation">What's your biggest motivation to eat well?</InputGroupLabel>
          {sortBy(motivations, 'position').map((item) => (
            <RadioButton
              name="motivationId"
              label={item.name}
              value={item.id}
              onChange={onChange}
              checked={formData.motivationId === item.id}
              key={item.id}
            />
          ))}
        </FormGroup>
        <ContentButton type="submit" disabled={!this.isValid() || isProcessing}>
          Continue
        </ContentButton>
      </ContentForm>
    );
  }
}

const RadioButton = styled(Radio)`
  margin-bottom: 1em;
`;

export default AboutYouPage;

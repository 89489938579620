import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled, { keyframes } from 'styled-components';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import moment from 'moment';

import { prepaidBundlePath , prepaidReviewPath } from 'onramp/api/navigation';
import Money from 'core/api/Money';
import { EXTERNAL_LINKS, HELP_EMAIL } from 'core/api/constants';
import { GET_PREPAID_PURCHASE, CURRENT_USER } from 'core/api/apollo/queries';
import { Fade } from 'core/ui/animations';
import BodyScrollManager from 'core/ui/components/BodyScrollManager';
import { Backdrop, H2, Image, Link, Logo, Text } from 'core/ui/atoms';
import { compareDates, displayDate } from 'core/ui/dateHelpers';

import { NUM_DAY_OFFSETS } from 'onramp/constants';
import { Page, PageContent } from 'onramp/ui/components';
import Footer from 'core/ui/components/Footer';


import IMAGE from 'dashboard/assets/images/Welcome.png';

const ConfettiColors = [
  '#5D9EC9',
  '#FEC5BA',
  '#F46B3B',
  '#E6D9C8',
  '#BDCBC1',
  '#D64D4D',
  '#F2ACB1',
  '#E05151',
  '#D3BCA0',
];

function PrepaidConfirmationPage() {
  return (
    <Page backgroundColor="#EFECE9">
      <PageContent>
        <ConfirmationModal />
      </PageContent>
    </Page>
  );
}

function ConfirmationModal() {
  const { loading: purchaseLoading, data: { getPrepaidPurchase: purchase = {} } = {} } = useQuery(
    GET_PREPAID_PURCHASE,
    { fetchPolicy: 'network-only' },
  );

  const { loading: userLoading, data: { currentUser } = {}  } = useQuery(
    CURRENT_USER,
  );
  const history = useHistory();
const { height, width } = useWindowSize();

useEffect(() => {
  if (userLoading) return;
  const { nextOneTimeProgram = {} } = currentUser ;
  if (!(nextOneTimeProgram && nextOneTimeProgram.invoiced)) {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(prepaidReviewPath());
    }
  }
}, [userLoading, history, currentUser]);

  if (userLoading || purchaseLoading || !purchase) {
    return null;
  }

  const { nextOneTimeProgram ={}, primaryAddress={} } = currentUser ;

  const {
    programBundle: { prepaidProgram: program = {}, schedule, size } = {},
    bundleReservations = {},
  } = nextOneTimeProgram;

  const {
    deliveryOption: { deliveryMethod },
  } = primaryAddress;

  const numWeeks = bundleReservations.length;
  const deliveryOffset = NUM_DAY_OFFSETS[deliveryMethod];
  const deliveryDays = schedule.deliveryDays.map((day) =>
    moment().day(day.index).add(deliveryOffset, 'days').format('dddd[s]'),
  );

  const startDates = bundleReservations
    .map((r) => r.deliveryPeriod.startDate)
    .sort((a, b) => compareDates(a.startDate, b.startDate));

  return ReactDOM.createPortal(
    <Fade show={true}>
      <Container data-confirmation-modal>
        <BodyScrollManager disabled={true} />
        <CustomBackdrop $color={'rgba(255, 248, 240, 0.92)'} />
        <Confetti height={height} width={width} colors={ConfettiColors} />

        <ModalContent>
          <CustomLogo />
          <H2>Congratulations, your purchase is complete!</H2>

          <div>
            <Text  text-size="medium">
              Your credit card has been charged <strong>{Money(purchase.total).toFormat()}</strong>.
              You'll be receiving {numWeeks} week{numWeeks > 1 ? 's' : ''} of our{' '}
              <strong>{program.name} Program</strong> with {size.name} meals.
            </Text>

            <Text  text-size="medium">
              {numWeeks > 1 ? 'Starting on' : 'On'} the week of{' '}
              <strong>{displayDate(startDates[0], 'MMMM Do')}</strong>, we'll deliver your food on{' '}
              <strong>{deliveryDays.join(' and ')} by 12.00 PM</strong>.
            </Text>

            {numWeeks > 1 ? (
              <Text  text-size="medium">
                {' '}
                Your last delivery of <strong>{program.name} Program</strong> will be on{' '}
                <strong>{displayDate(startDates[numWeeks - 1], 'dddd, MMMM Do')}</strong>.{' '}
              </Text>
            ) : null}

            <Text  text-size="medium">
              We'll also send you a confirmation email to you at{' '}
              <strong>{currentUser.email}</strong>, so keep an eye out for it!
            </Text>

            <Text  text-size="medium">
              If you need anything, we're here. Email{' '}
              <Link href={EXTERNAL_LINKS.contactMail} target="_blank">
                {HELP_EMAIL}
              </Link>
              .
            </Text>
          </div>

          <Links>
            <Link button btn-primary href={prepaidBundlePath()}>
              Reorder
            </Link>
          </Links>

          <FooterImage src={IMAGE} />
          <ModalFooter logoutReload={true} />
        </ModalContent>
      </Container>
    </Fade>,
    document.getElementById('modal-root'),
  );
}

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: ${(p) => p.theme.zIndex.modal};
  overflow: hidden;
`;

const CustomBackdrop = styled(Backdrop)`
  transition: background-color 0.25s ease-in-out;
  background-color: ${(p) => p.$color};
  backdrop-filter: blur(10px);
`;

const CustomLogo = styled(Logo)`
  font-size: ${(p) => p.theme.typography.sizes.large};
  position: relative;
  z-index: 100;
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  min-height: 325px;
  margin: 18vh auto 0 auto;

  ${(p) => p.theme.max('sm')`
    margin-top: 15vh;
    padding: 0 5vw;
  `}
`;

const Links = styled.footer`
  margin-top: 5vh;
  a, button {
    margin: 0.5em 1em;
  }
`;

const footerImageUp = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 150%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 100%);
  }
`;

const FooterImage = styled(Image)`
  position: absolute;
  z-index: 1;
  width: 80vh;
  left: 50%;
  bottom: -3vh;
  opacity: 0;
  transform: translate(-50%, 150%);

  animation: ${footerImageUp} 0.5s ease-in-out 0.5s;
  animation-fill-mode: forwards;
`;

const ModalFooter = styled(Footer)`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100vw;
`;

export default PrepaidConfirmationPage;
